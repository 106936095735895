import { NotifierOptions } from 'angular-notifier';
export const customNotifierOptions: NotifierOptions = {
    position: {
      horizontal: {
        position: 'middle',
        distance: 12
      },
      vertical: {
        position: 'top',
        distance: 12,
        gap: 20
      }
    },
    theme: 'material',
    behaviour: {
      autoHide: 2500,
      onClick: 'hide',
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
    },
    animations: {
      enabled: true,
      show: {
        preset: 'slide',
        speed: 300,
        easing: 'ease'
      },
      hide: {
        preset: 'fade',
        speed: 700,
        easing: 'ease',
        offset: 50
      },
      shift: {
        speed: 300,
        easing: 'ease'
      },
      overlap: 150
    }
  };
