import { Component, OnInit, Renderer, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import { ApiserviceService } from '../../../services/api/apiservice.service';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css'],
  providers:[DatePipe]
})
export class AdsComponent implements OnInit {

  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';

  constructor(private api: ApiserviceService, private location: Location, private router: Router, renderer: Renderer,
    private notifierService: NotifierService, private spinner: NgxSpinnerService,
    private confirmDialog: ConfirmationDialogComponent) {
      this.notifier = notifierService;

      this.elmentclear = renderer.listen('document', 'click', (event) => {

        // Do something with 'event'
        event.preventDefault();
        if (event.target.className === `glyphicon glyphicon-edit`) {
          if (event.target.parentElement.id === 'user_edit') {
            const formData = this.tableWidget.rows().data();
            const id = event.target.parentElement.attributes[3].nodeValue;
            $.each(formData, (key, value) => {
              // tslint:disable-next-line: radix
              if (value.id === parseInt(id)) {
                this.api.postDetail = value;
              }
            });
            this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);
  
          }
        }
        if (event.target.className === `glyphicon glyphicon-trash`) {
          if (event.target.parentElement.id === 'event_delete') {
  
            // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
            // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
            const id = event.target.parentElement.attributes[3].nodeValue;
  
            this.onDeleteEvent(id);
          }
        }
        if (event.target.className === `glyphicon glyphicon-file`) {
          if (event.target.parentElement.id === 'view_posts') {
  
            // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
            // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
            const id = event.target.parentElement.attributes[3].nodeValue;
            const name = event.target.parentElement.attributes[4].nodeValue;
            this.router.navigate(['post_list/event/' + id + '/' + name]);
          }
        }
  
        if (event.target.className === `glyphicon glyphicon-user`) {
  
          const id = event.target.parentElement.attributes[3].nodeValue;
          const name = event.target.parentElement.attributes[4].nodeValue;
          this.router.navigate(['/user_list/event/' + id + '/' + name]);
  
        }
  
  
      });
     }

     OnShowPopup() {
      const actionVal = confirm('Are you sure to delete?');
      return actionVal;
    }

    onDeleteEvent(id) {
      const actiondata = this.confirmDialog.OnShowPopup();
      if (actiondata) {
        this.spinner.show();
        this.api.onEventStatusUpdate(id, 'isDeleted', 1).subscribe(data => {
  
          this.api.actionMessage.next({ type: 'success', message: 'Event Deleted Successfully!' });
  
          this.tableWidget.draw();
          this.spinner.hide();
        },
          (error) => {
            this.api.actionMessage.next({ type: 'error', message: 'Event not Deleted! Something went wrong.' });
  
            this.spinner.hide();
          },
          () => {
          });
      }
    }

  ngOnInit() {
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}adposts/list`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0'|| xhr.status === 401) {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'title' },
        // { data: 'eventDate' },
        // { data: 'authorName' },
        { data: 'createAt' },
        { data: 'options' }
      ],

      columnDefs: [
        /* {
          targets: 2,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        }, */

        /* {
          targets: 4,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        }, */
        {
          targets: 2,
          searchable: false,
          orderable: true,
          // className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        },

        {
          targets: 3,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            return '<a id="user_edit" data="app-ads/edit"'
              + 'title="EDIT" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-edit"></span>'
              + '</a>';
          }
        /* {
          targets: 5,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            return '<a id="user_edit" data="event_list/edit"'
              + 'title="EDIT" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-edit"></span>'
              + '</a><a id="view_posts" data="post_list/events/' + full.id + '"'
              + 'title="View Attending Users" dataArray="' + full.id + '" name="'
              + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-user"></span>'
              + '</a>';
          } */
        }],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[0, 'desc']]

    });
  }

  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }


  }

  onAddTopic() {

    this.router.navigate(['event_list/add']);
  }

}
