import { Injectable } from '@angular/core';
import * as AWSCognito from 'amazon-cognito-identity-js';

import { environment } from '../../environments/environment';

(window as any)['global'] = window;

export interface Callback {
  callback(): void;
  callbackWithParam(result: any): void;
}

@Injectable(
//   {
//   providedIn: 'root'
// }
)
export class BarseltreffServiceProvider {

  _POOL_DATA = {
    UserPoolId: environment.aws.userPoolId,
    ClientId: environment.aws.poolClientId
  };

  constructor() { }

  signUp(email, password) {
    return new Promise((resolved, reject) => {
      const userPool = new AWSCognito.CognitoUserPool(this._POOL_DATA);

      const userAttribute = [];
      userAttribute.push(
        new AWSCognito.CognitoUserAttribute({ Name: 'email', Value: email }),
      );

      userPool.signUp(email, password, userAttribute, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolved(result);
        }
      });
    });
  }

  confirmUser(verificationCode, userName) {
    return new Promise((resolved, reject) => {
      const userPool = new AWSCognito.CognitoUserPool(this._POOL_DATA);

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: userName,
        Pool: userPool,
      });

      cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolved(result);
        }
      });
    });
  }

  authenticate(email, password) {
    return new Promise((resolved, reject) => {
      const userPool = new AWSCognito.CognitoUserPool(this._POOL_DATA);

      const authDetails = new AWSCognito.AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
          resolved(result);
        },
        onFailure: err => {
          reject(err);
        },
        newPasswordRequired: userAttributes => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          userAttributes.email = email;
          delete userAttributes.email_verified;

          cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: (result) => { },
            onFailure: (error) => {
              reject(error);
            },
          });
        },
      });
    });
  }

  forgotPassword(username: string): any {
    return new Promise((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: username,
        Pool: this.getUserPool(),
      });

      cognitoUser.forgotPassword({
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          console.error(err.message);
          reject(err);
        },
        inputVerificationCode(): void {
          resolve();
        },
      });
    });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string): any {
    return new Promise((resolve, reject) => {
      const cognitoUser: any = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this.getUserPool(),
      });

      cognitoUser.confirmPassword(verificationCode, password, {
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          reject(err);
        },
      });
    });
  }

  getUserPool(): any {
    return new AWSCognito.CognitoUserPool(this._POOL_DATA);
  }

  getCurrentUser(): any {
    return this.getUserPool()
      .getCurrentUser();
  }

  getParameter(name: string, callback: Callback): any {
    const cognitoUser = this.getCurrentUser();

    if (cognitoUser !== null)
      cognitoUser.getSession((error, session) => {
        if (error)
          console.error('Couldn\'t retrieve the user');
        else
          cognitoUser.getUserAttributes((err, result) => {
            if (err)
              console.error(`in getParameters: ${err}`);
            else {
              let isFound = false;
              result.forEach(item => {
                if (item['Name'] === name) {
                  callback.callbackWithParam(item['Value']);
                  isFound = true;
                }
              });

              if (!isFound)
                callback.callbackWithParam(undefined);
            }
          });
      });
    else
      callback.callbackWithParam(null);
  }

  getIdToken(callback: Callback): void {
    if (callback == null)
      throw new Error(('callback in getIdToken is null...returning'));

    if (this.getCurrentUser() !== null)
      this.getCurrentUser()
        .getSession((err, session) => {
          if (err) {
            console.error(`Can't set the credentials: ${err}`);
            callback.callbackWithParam(null);
          } else
            if (session.isValid())
              callback.callbackWithParam(session.getIdToken()
                .getJwtToken());
            else
              this._refreshSession(session.refreshToken)
                .then(idToken => {
                  callback.callbackWithParam(idToken);
                })
                .catch(error => {
                  console.error(error);
                  callback.callbackWithParam(null);
                });
        });
    else
      callback.callbackWithParam(null);
  }

  private _refreshSession(refreshToken): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getCurrentUser()
        .refreshSession(refreshToken, (err, session) => {
          if (err)
            reject(`Refresh token expired - ${err}`);

          resolve(session.getIdToken()
            .getJwtToken());
        });
    });
  }
}
