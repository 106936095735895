import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/internal/operators';
import { Observable, of, from, throwError, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BarseltreffServiceProvider } from '../barseltreff-service-provider.service';
import { AuthService } from '../auth.service';

@Injectable()

export class ApiserviceService {
  apiUrl = environment.apiUrl;
  version: string = environment.version;
  fileUrl: string = environment.cdnUrl;
  userInformation = new Subject<any>();
  currentUserId = 0;
  userEditData: any = {};
  actionMessage = new Subject<any>();
  key = environment.aws.key;
  secret = environment.aws.secret;
  countryId = environment.aws.countryId;
  bucketName = environment.bucketName;
  postDetail: any = [];
  notificationData = new Subject<any>();


  constructor(private http: HttpClient, private cUtil: BarseltreffServiceProvider, private authService: AuthService) { }



  getUser(email: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `user/actions/getuser/?email=${email}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  getTopicDetail(id: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `user/actions/gettopicdetail/?id=${id}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  deleteUser(email: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `user/delete`, { email }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onCloseAccount(pageReference: string, userId: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };
          const apiUrl = 'https://vwmovt1uc4.execute-api.eu-west-1.amazonaws.com/dev/';

          return this.http.get(apiUrl + `user/${userId}/close-account?userId=${userId}`, httpOptions)
            .pipe(
              map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onVerifyAccount(pageReference: string, userId: any, user: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };
          const apiUrl = 'https://vwmovt1uc4.execute-api.eu-west-1.amazonaws.com/dev/';

          return this.http.post(apiUrl + `user/${userId}/updateUser`, user, httpOptions)
            .pipe(
              map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  blockUnblockUser(payload: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `user/actions/block`, payload, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  onCreateTopic(topic: any, imageData: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `user/actions/createtopic`, { topic, imageData }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  deleteTopic(id: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `topic/action/delete`, { id }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }



  onPostStatusUpdate(id: any, action: any, status: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `post/updatePostStatus`, { id, action, status }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  onAdPostDelete(id: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `adposts/delete`, { id }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  onGetPolls(postType: any, postId: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `user/actions/userPoll?postId=${postId}&postType=${postType}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  onGetPollAnswers(postType: any, postId: any, questionId: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl +
            `user/actions/getUserPollAnswers?postId=${postId}&postType=${postType}&questionId=${questionId}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onGetPostDetail(id: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `post/getPostDetail/?id=${id}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }



  onReportStatusUpdate(id: any, status: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `reports/updateReportStatus`, { id, status }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  onGetNotification(): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `notification/get `, httpOptions)
            .pipe(
              catchError(error => {
                this.nonAuthRedirect();
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onUpdateStatus(id, type, status): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `globalupdate/viewstatus?id=${id}&type=${type}&status=${status}`, httpOptions)
            .pipe(
              catchError(error => {
                this.nonAuthRedirect();
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }


  deleteGroup(id: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `group/delete`, { id }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onCreateGroup(group: any, imageData: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `group/createEditGroup`, { group, imageData }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onCreatePost(post: any, imageData: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `post/add`, { post, imageData }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }
  onEditPaget(post: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `page/edit`, { post }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }


  getListingTypeList(type: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `common/getlistingtypelist/?type=${type}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }


  onEventStatusUpdate(id: any, action: any, status: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `events/updatestatus`, { id, action, status }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onCreateEvents(event: any, imageData: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `events/create`, { event, imageData }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onCreateAds(adPost:any, imageData: any): Observable<any>{
    return from(this._getSessionToken())
    .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':'application/json',
              Authorization: token,
              appversion: this.version
            })
          };
          return this.http.post(this.apiUrl+ `adposts/create`,{adPost, imageData}, httpOptions)
          .pipe(
            catchError(error=>{
              this.nonAuthRedirect();
              return Observable.throw(error)
            })
          )
        }))
    )
  }

  onCreateRole(role: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `role/create`, { role }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }


  deleteRole(id: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `role/delete`, { id }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }



  onGetMembersAdmin(groupId): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `group/getmembersadmin?groupId=${groupId}`, httpOptions)
            .pipe(
              catchError(error => {
                this.nonAuthRedirect();
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  onGetCapacities(type: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.get(this.apiUrl + `capability/get/?roleId=${type}`, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }

  updateCapacity(data: any): Observable<any> {
    return from(this._getSessionToken())
      .pipe(
        mergeMap((token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              appversion: this.version
            })
          };

          return this.http.post(this.apiUrl + `capability/update`, { data }, httpOptions)
            .pipe(
              //    map(this._extractData),
              catchError(error => {
                this.nonAuthRedirect();
                console.log(error);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error);
              })
            );
        })));
  }


  private _getSessionToken(): Promise<any> {
    return new Promise(resolve => {
      this.cUtil.getIdToken({
        callback(): void {
          // calback
        },
        callbackWithParam(token: any): void {
          resolve(token);
        }
      });
    });
  }

  nonAuthRedirect() {
    this.authService.nonAuthRedirect('');
  }
  _extractData(response: any): any {
    if (response && (response.status < 200 || response.status >= 300)) {
      this.nonAuthRedirect();
      throw new Error(`Bad response status: ${response.status}`);
    }

    if (response && response._body !== '') {
      return response || {};
    } else {
      return {};
    }
  }





  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }
  // private handleError(err: HttpErrorResponse): Observable<any> {
  //   //handle your auth error or rethrow
  //   if (err.status === 401) {
  //     //navigate /delete cookies or whatever
  //     console.log('handled error ' + err.status);
  //   //  this.router.navigate([`/login`]);
  // tslint:disable-next-line: max-line-length
  //     // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
  //     return of(err.message);
  //   }
  //   throw err;
  // }

  private log(message: string) {
    console.log(message);
  }
}
