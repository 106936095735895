import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
  providers: [DatePipe]
})
export class RoleListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';

  // tslint:disable-next-line: deprecation
  constructor(
    private api: ApiserviceService,
    public location: Location,
    private router: Router,
    public renderer: Renderer2,
    public notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private confirmDialog: ConfirmationDialogComponent) {
    this.notifier = notifierService;
    this.elmentclear = renderer.listen('document', 'click', (event) => {

      // Do something with 'event'
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-edit`) {
        if (event.target.parentElement.id === 'role_list') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const formData = this.tableWidget.rows().data();
          $.each(formData, (key, value) => {
            // tslint:disable-next-line: radix
            if (value.id === parseInt(id)) {
              this.api.postDetail = value;

            }
          });
          this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);

        }
      }
      if (event.target.className === `glyphicon glyphicon-user`) {
        if (event.target.parentElement.id === 'user_list') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const role = event.target.parentElement.attributes[4].nodeValue;
          console.log(role, 'role', id);
          this.router.navigate(['user_list/member/role/' + id + '/' + role]);
        }
      }
      if (event.target.className === `glyphicon glyphicon-trash`) {
        if (event.target.parentElement.id === 'topic_delete') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const id = event.target.parentElement.attributes[3].nodeValue;

          this.onDeleteRole(id);
        }
      }

      if (event.target.className === `glyphicon glyphicon-check`) {
        if (event.target.parentElement.id === 'view_capability') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          this.router.navigate(['role_list/capability/' + id]);
          //alert('under development');
        }
      }


      if (event.target.className === `cursorpointer user_list_from_group`) {
        if (event.target.id === 'user_list_from_group') {
          const id = event.target.attributes[1].nodeValue;
          const groupName = event.target.attributes[2].nodeValue;
          this.router.navigate(['user_list/member/group/' + id + '/' + groupName]);

        }
      }

    });

  }

  OnShowPopup() {
    const actionVal = confirm('Are you sure to delete?');
    return actionVal;
  }
  onDeleteRole(id) {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteRole(id).subscribe(data => {
        if (data[1].status === false) {
          alert(`${data[0].assignedUsers} user(s) are in this role. Please remove roles from user(s) to delete role!`);
        } else {
          this.api.actionMessage.next({ type: 'success', message: 'Role Deleted Successfully!' });
        }
        this.tableWidget.draw();
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Role not Deleted! Something went wrong.' });

          this.spinner.hide();
        },
        () => {
        });
    }
  }

  ngOnInit() {
    //   if (typeof this.notifier.notify === 'function') {
    //   this.api.actionMessage.subscribe(messageData => {
    //     this.notifier.notify(messageData.type, messageData.message);
    //     this.notifier = '';
    //   });
    // }
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}role/list`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'name' },
        { data: 'options' }
      ],

      columnDefs: [
        {
          targets: 2,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            let buttonHtml = '<a id="role_list" data="role_list/edit"'
              + 'title="EDIT" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-edit"></span>'
              + '</a><a id="view_capability" data="post_list/topics/' + full.id + '"'
              + 'title="Edit Role Capabilities" dataArray="' + full.id + '" name="'
              + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-check"></span>'
              + '</a><a id="user_list" data="user_list/roles/' + full.id + '"'
              + 'title="User list by role" dataArray="' + full.id + '" name="'
              + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-user"></span>'
              + '</a>';
            if (full.id !== 1 && full.id !== 2) {
              buttonHtml = buttonHtml + '<a id="topic_delete" data="group_list/edit/' + full.id + '"'
                + 'title="Delete" dataArray="' + full.id +
                '" class="action_buttons"><span style="color:red" class="glyphicon glyphicon-trash"></span>'
                + '</a>';
            }
            return buttonHtml;
          }
        }],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[0, 'desc']]

    });
  }


  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }


  }

  onAddTopic() {

    this.router.navigate(['topic_list/add']);
  }
}
