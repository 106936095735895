import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';


import { from } from 'rxjs';
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css'],
  providers: [DatePipe]
})
export class ViewReportComponent implements OnInit {
  userId = 0;
  profileImage = '';
  postDetail: any = [];
  pollsList: any = [];
  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService,
              private confirmDialog: ConfirmationDialogComponent
  ) { }

  ngOnInit() {
    this.postDetail = this.api.postDetail;

    if (this.postDetail.length === 0) {
      this.router.navigate(['report_list']);
    } else {
      if (this.postDetail.image !== '' && this.postDetail.image !== null) {
        this.profileImage = `${this.api.fileUrl}/posts/${this.postDetail.image}`;
      }

      if (!this.postDetail.isViewed) {
        this.onUpdateStatus();
      }
    }
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onStatusChange(status) {
    status = !status;
    const statusMessage = status ? 'Are you sure to Close report?' : 'Are you sure to Open report?';
    const actiondata = this.confirmDialog.OnShowPopup(statusMessage);
    if (actiondata) {



      this.onAction(this.postDetail.id, status);
    }
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }


  onAction(id, status) {
    this.spinner.show();
    this.api.onReportStatusUpdate(id, status).subscribe(data => {
      this.postDetail.Actionstatus = status;
      this.spinner.hide();
    },
      (error) => {
        this.router.navigate(['/report_list']);
        this.spinner.hide();
      },
      () => {
      });
  }


  onUpdateStatus() {
    const type = 'report';
    const status = 1;
    const id = this.postDetail.id;
    this.api.onUpdateStatus(id, type, status).subscribe(data => {
      //this.postDetail.Actionstatus = status;
      this.spinner.hide();
    },
      (error) => {
        this.router.navigate(['/report_list']);
        this.spinner.hide();
      },
      () => {
      });

  }

}
