import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { Location } from '@angular/common';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';


import { from } from 'rxjs';
@Component({
  selector: 'app-view-poll-response',
  templateUrl: './view-poll-response.component.html',
  styleUrls: ['./view-poll-response.component.css'],
  providers: [DatePipe]
})
export class ViewPollResponseComponent implements OnInit {
  userId = 0;
  postId: any = 0;
  postType: any = 0;
  id: any = 0;
  pollQuestion = '';
  pollsAnswerList: any = [];
  pollOptions: any = [];
  polssOptionsCount: any = [];
  postDetail: any = [];

  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService, private location: Location
  ) {
    // this.spinner.show();
    console.log(this.api.postDetail, 'this.api.postDetail');

    this.route.paramMap.subscribe(params => {
      this.postId = params.get('id');
      this.postType = params.get('id2');
      this.id = params.get('id3');
      console.log(params, 'params', this.postType); // Print the parameter to the console.
      this.onGetPollAnswers(this.postType, this.postId);
    });

  }

  ngOnInit() {
    this.postDetail = this.api.postDetail;
    if (this.postDetail.length === 0) {
      this.router.navigate(['poll_list']);
    }
    const poll: any = [{}];
    if (this.postDetail.options !== undefined && this.postDetail.options !== '') {
      const options = JSON.parse(this.postDetail.options);
      poll.options = options;

      const datePipe = new DatePipe('en-EU');
      poll.createdAt = datePipe.transform(this.postDetail.created_at, 'd/M/yyyy, h:mm a');
      poll.postType = this.postDetail.type;
      poll.id = this.postDetail.id;
      this.pollOptions.push(poll);
      this.pollQuestion = this.postDetail.title;
      this.pollOptions = poll.options;
    }

  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }





  onGetPolls(postType, postId) {
    this.spinner.show();
    this.api.onGetPolls(postType, postId).subscribe(data => {
      if (data.length > 0) {
        for (const poll of data) {
          const options = JSON.parse(poll.options);
          poll.options = options;

          const datePipe = new DatePipe('en-EU');
          poll.createdAt = datePipe.transform(poll.createdAt, 'd/M/yyyy, h:mm a');
          // tslint:disable-next-line: radix
          if (parseInt(this.id) === poll.id) {
            this.pollQuestion = poll.label;
            this.pollOptions = poll.options;
          }
        }
        // this.pollsList = data;
      }

      this.onGetPollAnswers(postType, postId);
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.router.navigate(['/post_list']);
        this.spinner.hide();
      },
      () => {
      });

  }

  onGetPollAnswers(postType, postId) {
    this.spinner.show();
    this.api.onGetPollAnswers(postType, postId, this.id).subscribe(data => {
      if (data.length > 0) {
        for (const poll of data) {

          const datePipe = new DatePipe('en-EU');
          poll.createdAt = datePipe.transform(poll.createdAt, 'd/M/yyyy, h:mm a');
          this.pollsAnswerList.push(poll);
          let counter = 0;
          for (const options of this.pollOptions) {
            if (poll.answer === options) {
              if (this.polssOptionsCount[counter] !== undefined) {
                if (this.polssOptionsCount[counter][`${options}`] !== undefined) {
                  const count = this.polssOptionsCount[counter][`${options}`];
                  this.polssOptionsCount[counter][`${options}`] = count + 1;
                }
              } else {
                this.polssOptionsCount[counter] = { [`${options}`]: 1 };
                console.log(counter, 'counter', options);
              }
            }
            counter++;
            //  console.log(counter,'counter', options);
          }
        }

        console.log(this.polssOptionsCount);
        // this.pollsList = data;
      }
      this.spinner.hide();
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.router.navigate(['/post_list']);
        this.spinner.hide();
      },
      () => {
      });

  }

  onBack() {

    this.location.back();
  }

}
