import { Component, OnDestroy, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-ads-add-edit',
  templateUrl: './ads-add-edit.component.html',
  styleUrls: ['./ads-add-edit.component.css']
})
export class AdsAddEditComponent implements OnInit {
  topicId = 0;
  topicData: any = {};
  isEdit = 0;
  topicForm: FormGroup;
  isSubmited = false;
  notifier: any = '';
  userProfileData: any = {};
  min: any = '';
  error: any = { isError: false, errorMessage: '' };
  base64textString: any = '';
  fileData: File = null;
  imageUpdate = 0;
  previewUrl: any = null;

  constructor(
    private api: ApiserviceService,
    private router: Router,
    private spinner: NgxSpinnerService, 
    private notifierService: NotifierService,
    private confirmDialog: ConfirmationDialogComponent, 
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader, 
    private ngZone: NgZone
  ) { 
    this.notifier = notifierService;
    this.onDefineFormField();
    const userProfileData: any = localStorage.getItem('userProfileData');
    this.userProfileData = JSON.parse(userProfileData);
  }

  ngOnInit() {
    if (this.api.postDetail.id === undefined) {
      this.isEdit = 0;
      this.router.navigate(['/app-ads/add']);
    } else {
      this.isEdit = 1;
      this.topicData = this.api.postDetail;
      this.topicId = this.topicData.id;

      this.topicForm.patchValue({
        id: this.topicId,
        title: this.topicData.title,
        featureImage: this.topicData.featureImage,
        description: this.topicData.description,
        offer: this.topicData.offer,
        shortDescription: this.topicData.shortDescription,
        adLink: this.topicData.adLink,
        isActive: this.topicData.isActive,
      });

      if (this.topicData.featureImage !== '' && this.topicData.featureImage !== null) {
        this.previewUrl = `${this.api.fileUrl}/posts/${this.topicData.featureImage}`;
      }

    }
  }

  onDeleteAd() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.onAdPostDelete(this.topicData.id).subscribe(() => {

        this.api.actionMessage.next({ type: 'success', message: 'Ad Deleted Successfully!' });

        this.router.navigate(['/app-ads']);
        this.spinner.hide();
      },
        () => {
          this.api.actionMessage.next({ type: 'error', message: 'Ad not Deleted! Something went wrong.' });
          this.router.navigate(['/app-ads']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  fileProgress(evt: any) {
    this.fileData = evt.target.files[0] as File;
    this.preview();
    // this.postForm.controls.image.setValue(this.fileData.name);

    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  preview() {
    this.imageUpdate = 1;
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (event) => {
      this.previewUrl = reader.result;
    };
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  onSubmit() {
    let imageData = {
    };
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    if (this.topicForm.valid) {
      this.spinner.show();
      if (this.imageUpdate) {
        let fileName = `${timestamp}/${this.fileData.name}`;
        let mime: any = this.fileData.type;
        if (mime !== '') {
          mime = mime.split('/');
          if (mime[1] !== undefined) {
            mime = mime[1];
            if (mime !== '') {
              fileName = `${timestamp}.${mime}`;
            }
          }
        }

        imageData = {
          key: this.api.key,
          secret: this.api.secret,
          name: `${fileName}`,
          filename: `posts/${fileName}`,
          filedata: this.base64textString,
          countryId: this.api.countryId,
          bucket: this.api.bucketName
        };
        
      }
      const topic = this.topicForm.value;
      // tslint:disable-next-line: no-shadowed-variable
      this.api.onCreateAds(topic, imageData).subscribe(finalTopicData => {
        let message = 'Ad Added successfully.';
        let type = 'success';
        if (finalTopicData.affectedRows) {
          if (this.isEdit) {
            message = 'Ad updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/app-ads']);
        this.spinner.hide();
      },
        () => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
        () => { this.spinner.hide(); }

      );

    } else {
      console.log(this.error, 'this.error');

    }

  }

  onDefineFormField() {
    this.topicForm = this.fb.group({
      id: new FormControl(null),
      title: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      featureImage: new FormControl(null),
      offer: new FormControl(null),
      shortDescription: new FormControl(null),
      adLink: new FormControl(null),
      isActive: new FormControl(1, [Validators.required]),
    });
  }

  ngOnDestroy() {
    this.api.postDetail = [];
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }

}
