import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appfooter',
  templateUrl: './appfooter.component.html',
  styleUrls: ['./appfooter.component.css']
})
export class AppfooterComponent implements OnInit {
  d = new Date();
  year = this.d.getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
