import { Directive, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { FileSelectDirective } from 'ng2-file-upload';
import { fromEvent } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare var ImageCompressor: any;

const compressor = new ImageCompressor();

@Directive({
  selector: '[appImgCompressor]'
})
export class ImgCompressorDirective extends FileSelectDirective {
  @Output() nameuploader: any = new EventEmitter<any>();
  constructor(private zone: NgZone, private elementRef: ElementRef) {
    super(elementRef);
  }

  onChange(): any {
    // Retrieve the selected files.
    const files: FileList = this.element.nativeElement.files;

    this.zone.runOutsideAngular(() => {

      const promises: Promise<Blob>[] = [];


      // Compress each file and preserve a quality of 50%.
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        promises.push(compressor.compress(file, { quality: .5 }));
        // imageArray.push(compressor.compress(file, { quality: .5 }));
      }

      // When all promises resolve, then upload files to server.
      Promise.all(promises).then(Namefiles => this.uploadFile(Namefiles));

    });

  }

  protected uploadFile(files: any[]) {
    this.onFileSelected.emit(files);
    this.onNameuploader(files[0]);
    if (!this.isEmptyAfterSelection()) {
      return;
    }
  }


  onNameuploader(UpdaetdFile) {
    const fileReader = new FileReader();
    return this.imageToBase64(fileReader, UpdaetdFile)
      .subscribe(base64image => {
        base64image = base64image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.nameuploader.emit(base64image);
        // do something with base64 image..
      });

  }


  imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }
}
