import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../services/api/apiservice.service';
@Component({
  selector: 'app-appmenu',
  templateUrl: './appmenu.component.html',
  styleUrls: ['./appmenu.component.css']
})
export class AppmenuComponent implements OnInit {
  userName: any = '';
  profileImage = '';
  activeStatus = 0;
  reportpendingToView = 0;
  constructor(private api: ApiserviceService) { }

  ngOnInit() {
    // tslint:disable-next-line: deprecation
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      trees.tree();
    });
    this.api.userInformation.subscribe(data => {
      this.userName = this.titleCaseWord((data.name));
      if (data.profilePicture !== '' && data.profilePicture !== null) {
        this.profileImage = `${this.api.fileUrl}/users/${data.uuid}/${data.profilePicture}`;
      }
      console.log(data);
    },
      error => { console.log(error, 'error'); },
      () => {
        console.log('complete');
      });


    this.api.notificationData.subscribe(notificationVal => {
      if (notificationVal.length > 0) {
        this.activeStatus = notificationVal[0].activeStatus;
        this.reportpendingToView = notificationVal[1].reportpendingToView;
      }

    });

  }

  titleCaseWord(word: string) {
    if (!word) { return word; }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}
