import { Component, OnInit, Renderer, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.css'],
  providers: [DatePipe]
})
export class TopicListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';

  // tslint:disable-next-line: deprecation
  constructor(private api: ApiserviceService, private location: Location, private router: Router, renderer: Renderer,
              private notifierService: NotifierService, private spinner: NgxSpinnerService,
              private confirmDialog: ConfirmationDialogComponent) {
    this.notifier = notifierService;
    this.elmentclear = renderer.listen('document', 'click', (event) => {

      // Do something with 'event'
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-edit`) {
        if (event.target.parentElement.id === 'user_edit') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const email = event.target.parentElement.attributes[3].nodeValue;
          const id = event.target.parentElement.attributes[1].nodeValue;
          this.api.userEditData = { email, id };
          this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);
        }
      }
      if (event.target.className === `glyphicon glyphicon-trash`) {
        if (event.target.parentElement.id === 'topic_delete') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const id = event.target.parentElement.attributes[3].nodeValue;

          this.onDeleteTopic(id);
        }
      }
      if (event.target.className === `glyphicon glyphicon-file`) {
        if (event.target.parentElement.id === 'view_posts') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const id = event.target.parentElement.attributes[3].nodeValue;
          const name = event.target.parentElement.attributes[4].nodeValue;
          this.router.navigate(['post_list/topic/' + id + '/' + name]);
        }
      }

      if (event.target.className === `glyphicon glyphicon-stats`) {
        if (event.target.parentElement.id === 'view_polls') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const id = event.target.parentElement.attributes[3].nodeValue;
          const name = event.target.parentElement.attributes[4].nodeValue;
          this.router.navigate(['poll_list/topic/' + id + '/' + name]);
        }
      }


    });

  }

  OnShowPopup() {
    const actionVal = confirm('Are you sure to delete?');
    return actionVal;
  }
  onDeleteTopic(id) {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteTopic(id).subscribe(data => {

        this.api.actionMessage.next({ type: 'success', message: 'Topic Deleted Successfully!' });

        this.tableWidget.draw();
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Topic not Deleted! Something went wrong.' });

          this.spinner.hide();
        },
        () => {
        });
    }
  }

  ngOnInit() {
    //   if (typeof this.notifier.notify === 'function') {
    //   this.api.actionMessage.subscribe(messageData => {
    //     this.notifier.notify(messageData.type, messageData.message);
    //     this.notifier = '';
    //   });
    // }
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}user/actions/gettopiclist`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'name' },
        { data: 'is_featured' },
        { data: 'created_date' },
        { data: 'options' }
      ],

      columnDefs: [
        {
          targets: 3,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        },
        {
          targets: 2,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            let isFeatured = 'No';
            if (data === 1) {
              isFeatured = 'Yes';
            }
            return isFeatured;
          }
        },
        {
          targets: 4,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            return '<a id="user_edit" data="topic_list/edit/' + full.id + '"'
              + 'title="EDIT" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-edit"></span>'
              + '</a><a id="view_posts" data="post_list/topics/' + full.id + '"'
              + 'title="View Posts" dataArray="' + full.id + '" name="'
               + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-file"></span>'
              + '</a><a id="view_polls" data="poll_list/topics/' + full.id + '"'
              + 'title="View Polls" dataArray="' + full.id + '" name="'
               + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-stats"></span>'
              + '</a><a id="topic_delete" data="topic_list/edit/' + full.id + '"'
              + 'title="Delete" dataArray="' + full.id +
              '" class="action_buttons"><span style="color:red" class="glyphicon glyphicon-trash"></span>'
              + '</a>';
          }
        }],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[0, 'desc']]

    });
  }


  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }


  }

  onAddTopic() {

    this.router.navigate(['topic_list/add']);
  }
}
