import { Component, OnInit, Renderer, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-poll-list',
  templateUrl: './poll-list.component.html',
  styleUrls: ['./poll-list.component.css'],
  providers: [DatePipe]
})
export class PollListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';
  postTypeId = '';
  postType = '';
  postName = '';

  // tslint:disable-next-line: deprecation
  constructor(private api: ApiserviceService, private location: Location, private router: Router, renderer: Renderer,
              private notifierService: NotifierService, private spinner: NgxSpinnerService,
              private confirmDialog: ConfirmationDialogComponent, private route: ActivatedRoute) {
    this.notifier = notifierService;

    this.elmentclear = renderer.listen('document', 'click', (event) => {
      // Do something with 'event'
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-edit`) {
        if (event.target.parentElement.id === 'post_view') {
          const formData = this.tableWidget.rows().data();
          const id = event.target.parentElement.attributes[3].nodeValue;
          $.each(formData, (key, value) => {
            // tslint:disable-next-line: radix
            if (value.id === parseInt(id)) {
              this.api.postDetail = value;
            }
          });
          this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);
        }
      }
      if (event.target.className === `glyphicon glyphicon-play-circle`) {
        if (event.target.parentElement.id === 'post_status') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const status = event.target.parentElement.attributes[2].nodeValue;
          this.onActivateUser(status, id);
        }
      }
      if (event.target.className === `glyphicon glyphicon-exclamation-sign`) {
        if (event.target.parentElement.id === 'post_report_list') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          this.router.navigate(['report_list/' + id]);
          //  this.onActivateUser(status, id);
        }
      }
      if (event.target.className === `glyphicon glyphicon-trash`) {
        if (event.target.parentElement.id === 'post_delete') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const status = event.target.parentElement.attributes[2].nodeValue;
          this.onDeleteUser(id);
        }
      }

      if (event.target.className === `cursorpointer user_list_from_group`) {
        if (event.target.id === 'user_list_from_group') {
          const id = event.target.attributes[1].nodeValue;
          const groupName = event.target.attributes[2].nodeValue;
          this.router.navigate(['user_list/member/followers of/' + id + '/' + groupName]);

        }
      }

    });

    this.route.paramMap.subscribe(params => {
      this.postTypeId = params.get('id2');
      this.postType = params.get('id');
      this.postName = params.get('id3');
    });
  }

  ngOnInit() {
    // this.notifier.notify("success", "You are awesome! I mean it!");


    // this.api.actionMessage.subscribe(messageData => {
    //   this.notifier.notify(messageData.type, messageData.message);
    // });
    // this.notifier = '';
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      responsive: true,
      ajax: {
        url: `${this.api.apiUrl}post/getPostsList`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          const postTypeId = this.postTypeId;
          const postType = this.postType;
          d.postTypeFilter = { postTypeId, postType };
          d.postTypeName = 'poll';
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'title' },
        { data: 'author_name' },
        { data: 'type' },
        { data: 'followerCount' },
        { data: 'created_at' },
        { data: 'options' }
      ],
      // oSearch: {"sSearch": "San Francisco"},
      columnDefs: [
        //          {
        //    targets: 0,
        //    searchable: false,
        //    orderable: false,
        //    className: 'dt-body-center',
        //    render(data, type, full, meta) {
        //        return '<input class="innercheck" type="checkbox" name="id[]" value="'
        //           + $('<div/>').text(data).html() + '">';
        //    }
        // },
        {
          targets: 3,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            let valueData = '';
            if (data !== '' && data !== undefined && data !== null) {
              valueData = data.charAt(0).toUpperCase() + data.slice(1);
            } else {
              valueData = data;
            }
            return valueData;
          }
        },
        {
          targets: 4,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const value = '<a id="user_list_from_group" groupId="' + full.id +
              '"  groupName="' + full.title + '"  class="cursorpointer user_list_from_group" style="cursor: pointer">' + data + '</a>';
            return value;
          }
        },
        {
          targets: 5,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        },
        {
          targets: 6,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            let statusClass = 'span_red';
            let statusTitle = 'Mark as active';
            if (full.status === 1) {
              statusClass = '';
              statusTitle = 'Mark as in-active';
            }
            return '<a id="post_view" data="poll_list/' + full.id + '"'
              + 'title="EDIT" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-edit"></span>'
              + '</a><a id="post_status" data="poll_list/' + full.id + '"'
              + 'status="' + full.status + '" dataArray="' + full.id + '" title="' + statusTitle + '"  class="action_buttons ' +
              statusClass + '"><span class="glyphicon glyphicon-play-circle"></span>'
              + '</a><a id="post_report_list" data="report_list/' + full.id + '"'
              + 'title="View Reports" dataArray="' +
              full.id + '" class="action_buttons span_red"><span class="glyphicon glyphicon-exclamation-sign"></span>'
              + '</a><a id="post_delete" data="poll_list/' + full.id + '"'
              + 'title="Delete" dataArray="' + full.id + '" class="action_buttons span_red"><span class="glyphicon glyphicon-trash"></span>'
              + '</a>';
          }
        }
      ],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[3, 'desc']]

    });

  }

  onBack() {
    this.location.back();
  }

  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }

  }



  onActivateUser(status, id) {
    status = (status === '0') ? 1 : 0;
    const statusMessage = status ? 'Are you sure to make Active?' : 'Are you sure to make In-Active?';
    const actiondata = this.confirmDialog.OnShowPopup(statusMessage);
    if (actiondata) {

      this.onAction(id, 'status', status);
    }
  }

  onDeleteUser(id) {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.onAction(id, 'isDeleted', 1);
    }
  }

  onAction(id, action, status) {
    this.spinner.show();
    this.api.onPostStatusUpdate(id, action, status).subscribe(data => {

      this.api.actionMessage.next({ type: 'success', message: 'User Deleted Successfully!' });

      this.tableWidget.draw();
      this.spinner.hide();
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.router.navigate(['/poll_list']);
        this.spinner.hide();
      },
      () => {
      });
  }
}
