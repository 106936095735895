import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';



import { from } from 'rxjs';
@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.css']
})
export class AddEditRoleComponent implements OnInit {
  uploader: any;
  topicId = 0;
  topicData: any = {};
  profileImage = '';
  isEdit = 0;
  roleForm: FormGroup;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  base64textString = '';
  userProfileData: any = {};
  imageUpdate = 0;
  isSubmited = false;
  notifier: any = '';
  postDetail: any = [];
  constructor(
    private route: ActivatedRoute,
    private api: ApiserviceService,
    private router: Router,
    private spinner: NgxSpinnerService, private notifierService: NotifierService,
    private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder
  ) {
    this.notifier = notifierService;
    this.onDefineFormField();
    const userProfileData: any = localStorage.getItem('userProfileData');
    this.userProfileData = JSON.parse(userProfileData);
  }

  ngOnInit() {
    this.postDetail = this.api.postDetail;
    if (this.postDetail.length === 0) {
      this.router.navigate(['role_list/add']);
    } else {
      this.isEdit = 1;
      this.roleForm.patchValue({
        id: this.postDetail.id,
        name: this.postDetail.name
      });
    }

  }

  onDefineFormField() {
    this.roleForm = this.fb.group({

      id: new FormControl(null),
      name: new FormControl(null, [Validators.required])

    });
  }



  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onActivateUser() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onDeleteRole() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteRole(this.postDetail.id).subscribe(data => {
        if (data[1].status === false) {
          alert(`${data[0].assignedUsers} user(s) are in this role. Please remove roles from user(s) to delete role!`);
        } else {
          this.api.actionMessage.next({ type: 'success', message: 'Role Deleted Successfully!' });
          this.router.navigate(['/role_list']);
        }

        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Role not Deleted! Something went wrong.' });
          this.router.navigate(['/role_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  fileProgress(evt: any) {
    this.fileData = evt.target.files[0] as File;
    this.preview();
    this.roleForm.controls.image_name.setValue(this.fileData.name);

    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }
  preview() {
    this.imageUpdate = 1;
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (event) => {
      this.previewUrl = reader.result;
    };
  }


  onSubmit() {
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    console.log(this.roleForm.value);
    if (this.roleForm.valid) {
      this.spinner.show();
      if (this.imageUpdate) {
        this.roleForm.controls.image_name.setValue(`${timestamp}/${this.fileData.name}`);
      }
      const role = this.roleForm.value;
      // tslint:disable-next-line: no-shadowed-variable
      this.api.onCreateRole(role).subscribe(finalTopicData => {
        let message = 'Role Added successfully.';
        let type = 'success';
        if (finalTopicData.affectedRows) {
          if (this.isEdit) {
            message = 'Role updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/role_list']);
        this.spinner.hide();
      },
        error => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
        () => { this.spinner.hide(); }

      );

    }

  }

  nameuploader(UpdaetdFile) {
    this.base64textString = UpdaetdFile;
  }


}
