import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BarseltreffServiceProvider } from './services/barseltreff-service-provider.service';
import { ApiserviceService } from './services/api/apiservice.service';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotifierModule } from 'angular-notifier';
import { customNotifierOptions } from '../environments/notificationOptions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppheaderComponent } from './appheader/appheader.component';
import { AppfooterComponent } from './appfooter/appfooter.component';
import { AppmenuComponent } from './appmenu/appmenu.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { MainComponent } from './main/main.component';
import { AuthService } from './services/auth.service';
import { ContainerComponent } from './container/container.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
// tslint:disable-next-line: max-line-length
import { UserInterestSuggestionListComponent } from './pages/user/user-interest-suggestion-list/user-interest-suggestion-list.component';
import { AddEditComponent } from './pages/user/add-edit/add-edit.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { TopicListComponent } from './pages/topic/topic-list/topic-list.component';
import { TopicAddEditComponent } from './pages/topic/topic-add-edit/topic-add-edit.component';
import { ImgCompressorDirective } from './common/img-compressor/img-compressor.directive';
import { PostListComponent } from './pages/posts/post-list/post-list.component';
import { ViewPostComponent } from './pages/posts/view-post/view-post.component';
import { AddEditPostComponent } from './pages/posts/addedit-post/addedit-post.component';
import { ViewPollResponseComponent } from './pages/polls/view-poll-response/view-poll-response.component';
import { ReportListComponent } from './pages/report/report-list/report-list.component';
import { ViewReportComponent } from './pages/report/view-report/view-report.component';
import { GroupListComponent } from './pages/group/group-list/group-list.component';
import { GroupRequestListComponent } from './pages/group/group-request-list/group-request-list.component';
import { GroupAddEditComponent } from './pages/group/group-add-edit/group-add-edit.component';
import { CommentListComponent } from './pages/comment/comment-list/comment-list.component';
import { ViewCommentComponent } from './pages/comment/view-comment/view-comment.component';
import { PollListComponent } from './pages/polls/poll-list/poll-list.component';
import { PageListComponent } from './pages/pages/page-list/page-list.component';
import { AddEditPageComponent } from './pages/pages/addedit-page/addedit-page.component';
import { ViewPollComponent } from './pages/polls/view-poll/view-poll.component';
import { AddEditPollComponent } from './pages/polls/addedit-poll/addedit-poll.component';
import { EventListComponent } from './pages/event/event-list/event-list.component';
import { EventAddEditComponent } from './pages/event/event-add-edit/event-add-edit.component';
import { RoleListComponent } from './pages/role/role-list/role-list.component';
import { AddEditRoleComponent } from './pages/role/add-edit-role/add-edit-role.component';
import { EditRoleCapabilityComponent } from './pages/role/edit-role-capability/edit-role-capability.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { CKEditorModule } from 'ckeditor4-angular';
import { AdsComponent } from './pages/ad-post/ads-list/ads.component';
// import { AdsAddEditComponentComponent } from './ads-add-edit-component/ads-add-edit-component.component';
import { AdsAddEditComponent } from './pages/ad-post/ads-add-edit/ads-add-edit.component';





@NgModule({
  declarations: [
    AppComponent,
    AppheaderComponent,
    AppfooterComponent,
    AppmenuComponent,
    DashboardComponent,
    LoginComponent,
    LogoutComponent,
    MainComponent,
    ContainerComponent,
    UserListComponent,
    UserInterestSuggestionListComponent,
    AddEditComponent,
    ConfirmationDialogComponent,
    TopicListComponent,
    TopicAddEditComponent,
    ImgCompressorDirective,
    PostListComponent,
    ViewPostComponent,
    AddEditPostComponent,
    ViewPollResponseComponent,
    ReportListComponent,
    ViewReportComponent,
    GroupListComponent,
    GroupRequestListComponent,
    GroupAddEditComponent,
    CommentListComponent,
    ViewCommentComponent,
    PollListComponent,
    ViewPollComponent,
    AddEditPollComponent,
    EventListComponent,
    EventAddEditComponent,
    RoleListComponent,
    AddEditRoleComponent,
    EditRoleCapabilityComponent,
    PageListComponent,
    AddEditPageComponent,
    AdsComponent,
    // AdsAddEditComponentComponent,
    AdsAddEditComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAQGnI1aUcCuq4wnUtfG-FYNqPhCNGc458',
      libraries: ['places']
    }),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    DataTablesModule,
    NgxSpinnerModule,
    NotifierModule.withConfig(customNotifierOptions),
    OwlDateTimeModule, OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    CKEditorModule
  ],
  providers: [AuthService, BarseltreffServiceProvider, ApiserviceService, ConfirmationDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
