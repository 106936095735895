import { Component, OnDestroy, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';


declare var google: any;


@Component({
  selector: 'app-event-add-edit',
  templateUrl: './event-add-edit.component.html',
  styleUrls: ['./event-add-edit.component.css']
})
export class EventAddEditComponent implements OnInit, OnDestroy {
  uploader: any;
  topicId = 0;
  topicData: any = {};
  isEdit = 0;
  topicForm: FormGroup;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  base64textString = '';
  userProfileData: any = {};
  imageUpdate = 0;
  isSubmited = false;
  notifier: any = '';
  d = new Date();
  min: any = '';
  error: any = { isError: false, errorMessage: '' };
  isValidDateCustom: any;


  public latitude = 39.8282;
  public longitude = -98.5795;
  public searchControl: FormControl;
  public zoom = 4;

  @ViewChild('search', { static: false })
  private searchElementRef: ElementRef;

  constructor(
    private api: ApiserviceService,
    private router: Router,
    private spinner: NgxSpinnerService, private notifierService: NotifierService,
    private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone
  ) {
    this.notifier = notifierService;
    this.onDefineFormField();
    const userProfileData: any = localStorage.getItem('userProfileData');
    this.userProfileData = JSON.parse(userProfileData);
  }

  ngOnInit() {
    if (this.api.postDetail.id === undefined) {
      this.isEdit = 0;
      this.router.navigate(['/event_list/add']);
      this.setCurrentPosition();
    } else {
      this.isEdit = 1;
      this.topicData = this.api.postDetail;
      this.topicId = this.topicData.id;
      if (this.topicData.lat !== '') {
        this.latitude = parseFloat(this.topicData.lat);
        this.longitude = parseFloat(this.topicData.lng);
        this.zoom = 12;
      }

      this.topicForm.patchValue({
        id: this.topicId,
        name: this.topicData.name,
        description: this.topicData.description,
        lat: this.topicData.lat,
        lng: this.topicData.lng,
        address: this.topicData.address,
        eventDate: this.topicData.eventDate,
        authorId: this.topicData.authorId,
        isCancelled: this.topicData.isCancelled

      });




    }
    setTimeout(() => { this.onIntiMap(); }, 1500);

    if (this.isEdit === 0) {
      this.min = new Date(this.d.getFullYear(), this.d.getMonth(), this.d.getDate(), this.d.getHours(), this.d.getMinutes());
    }

  }

  onIntiMap() {

    // create search FormControl
    this.searchControl = new FormControl();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: any = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.topicForm.patchValue({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address: place.formatted_address
          });
          this.zoom = 12;
        });
      });
    });
  }


  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  onDefineFormField() {
    this.topicForm = this.fb.group({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      lat: new FormControl(this.latitude, [Validators.required]),
      lng: new FormControl(this.longitude, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      eventDate: new FormControl(null, [Validators.required]),
      authorId: new FormControl(this.api.currentUserId, [Validators.required]),
      isCancelled: new FormControl(0, [Validators.required]),
    });
  }
  dateValidator(c: AbstractControl): { [key: string]: boolean } {
    const value = c.value;
    console.log(value, 'value');
    if (value && typeof value === 'string') {
      const match = value.match(/^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/);
      if (!match) {
        return { dateInvalid: true };
      }
      const date = new Date(`${match[3]}-${match[1]}-${match[2]}`);
      if (isNaN(date.getTime())) {
        return { dateInvalid: true };
      }
    }
    return null;

  }


  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }

  onDeleteEvent() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.onEventStatusUpdate(this.topicData.id, 'isDeleted', 1).subscribe(() => {

        this.api.actionMessage.next({ type: 'success', message: 'Event Deleted Successfully!' });

        this.router.navigate(['/event_list']);
        this.spinner.hide();
      },
        () => {
          this.api.actionMessage.next({ type: 'error', message: 'Event not Deleted! Something went wrong.' });
          this.router.navigate(['/event_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  onSubmit() {
    this.validateDates();
    let imageData = {
    };
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    console.log(this.topicForm);
    console.log(this.topicForm.value);
    if (this.topicForm.valid) {
      this.spinner.show();
      if (this.imageUpdate) {
        let fileName = `${timestamp}/${this.fileData.name}`;
        let mime: any = this.fileData.type;
        if (mime !== '') {
          mime = mime.split('/');
          if (mime[1] !== undefined) {
            mime = mime[1];
            if (mime !== '') {
              fileName = `${timestamp}.${mime}`;
            }
          }
        }

        imageData = {
        };
      }
      const topic = this.topicForm.value;
      // tslint:disable-next-line: no-shadowed-variable
      this.api.onCreateEvents(topic, imageData).subscribe(finalTopicData => {
        let message = 'Topic Added successfully.';
        let type = 'success';
        if (finalTopicData.affectedRows) {
          if (this.isEdit) {
            message = 'Topic updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/event_list']);
        this.spinner.hide();
      },
        () => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
        () => { this.spinner.hide(); }

      );

    } else {
      console.log(this.error, 'this.error');

    }

  }

  nameuploader(UpdaetdFile) {
    this.base64textString = UpdaetdFile;
  }

  ngOnDestroy() {
    this.api.postDetail = [];
  }


  validateDates() {
    this.error = {};
    this.isValidDateCustom = false;


    return this.isValidDateCustom;
  }


}
