import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-group-request-list',
  templateUrl: './group-request-list.component.html',
  styleUrls: ['./group-request-list.component.css'],
  providers: [DatePipe]
})
export class GroupRequestListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';

  // tslint:disable-next-line: deprecation
  constructor(
    private api: ApiserviceService,
    private location: Location,
    private router: Router,
    public renderer: Renderer2,
    private notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private confirmDialog: ConfirmationDialogComponent) {
    this.notifier = notifierService;
    this.elmentclear = renderer.listen('document', 'click', (event) => {
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-exclamation-sign`) {
        if (event.target.parentElement.id === 'status') {
          let status = event.target.parentElement.attributes[2].nodeValue;
          const id = event.target.parentElement.attributes[4].nodeValue;

          // tslint:disable-next-line: radix
          if (1 !== parseInt(status)) {
            status = '1';
          } else {
            status = '0';
          }
          console.log(id, 'test', status);
          this.onUpdateStatus(id, status);
        }
      }


    });

  }

  OnShowPopup() {
    const actionVal = confirm('Are you sure to delete?');
    return actionVal;
  }
  onDeleteGroup(id) {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteGroup(id).subscribe(data => {

        this.api.actionMessage.next({ type: 'success', message: 'Group Deleted Successfully!' });

        this.tableWidget.draw();
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Group not Deleted! Something went wrong.' });

          this.spinner.hide();
        },
        () => {
        });
    }
  }

  ngOnInit() {
    //   if (typeof this.notifier.notify === 'function') {
    //   this.api.actionMessage.subscribe(messageData => {
    //     this.notifier.notify(messageData.type, messageData.message);
    //     this.notifier = '';
    //   });
    // }
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}group/getrequestlist`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'userName' },
        { data: 'group_name' },
        { data: 'status' },
        { data: 'requestcreatedAt' },
        { data: 'options' }
      ],

      columnDefs: [

        {
          targets: 2,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {

            const value = '<a id="user_list_from_group" groupId="' + full.id +
              '"  groupName="' + full.name + '"  class="cursorpointer user_list_from_group" style="cursor: pointer">' + data + '</a>';
            return value;
          }
        },
        {
          targets: 3,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            let isFeatured = 'Unapproved';
            if (data === 1) {
              isFeatured = 'Approved';
            }
            return isFeatured;
          }
        },
        {
          targets: 4,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        },
        {
          targets: 5,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';
            let color = '#3c8dbc';
            let requestStatusText = 'Click to Approve';
            // tslint:disable-next-line: radix
            if (parseInt(full.status) === 1) {
              color = 'red';
              requestStatusText = 'Click to Unapproved';
            }
            return '<a id="status" data="status' + full.id + '" statusData="' + full.status + '"'
              + 'title="' + requestStatusText + '" dataArray="' + full.id + '" class="action_buttons">' +
              '<span style="color:' + color + '" class="glyphicon glyphicon-exclamation-sign"></span>'
              + '</a>';
          }
        }],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[4, 'desc']]

    });
  }


  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }


  }

  onAddTopic() {

    this.router.navigate(['topic_list/add']);
  }


  onUpdateStatus(id, status): void {
    this.api.onUpdateStatus(id, 'groupMembers', status).subscribe(data => {
      console.log(data, 'data');
      this.tableWidget.draw();
    });
  }
}
