import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';


import { from } from 'rxjs';
@Component({
  selector: 'app-addedit-post',
  templateUrl: './addedit-post.component.html',
  styleUrls: ['./addedit-post.component.css'],
  providers: [DatePipe]
})
export class AddEditPostComponent implements OnInit, OnDestroy {
  userId = 0;
  profileImage = '';
  postDetail: any = [];
  pollsList: any = [];
  isAdd = 0;
  postForm: FormGroup;
  base64textString: any = '';
  fileData: File = null;
  imageUpdate = 0;
  isSubmited = false;
  previewUrl: any = null;
  typeIdList: any = [];
  typeName = '';

  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService,
              private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder
  ) {

    this.onDefineFormField();
  }
  onDefineFormField() {

    this.postForm = this.fb.group({

      id: new FormControl(null),
      title: new FormControl(null, [Validators.required]),
      postType: new FormControl('post'),
      type: new FormControl(null, [Validators.required]),
      typeId: new FormControl(null, [Validators.required]),
      author_id: new FormControl(this.api.currentUserId),
      content: new FormControl(null),
      image: new FormControl(null),
      status: new FormControl(false),

    });
  }
  ngOnInit() {
    this.postDetail = this.api.postDetail;
    if (this.postDetail.length === 0) {
      this.router.navigate(['post_add']);
      this.isAdd = 1;
    } else {
      if (this.postDetail.image !== '' && this.postDetail.image !== null) {
        this.profileImage = `${this.api.fileUrl}/posts/${this.postDetail.image}`;
        this.previewUrl = this.profileImage;

      }
      this.onGetTypeListApi(this.postDetail.type);
      this.postForm.patchValue({
        id: this.postDetail.id,
        title: this.postDetail.title,
        type: this.postDetail.type,
        typeId: this.postDetail.typeId,
        author_id: this.postDetail.author_id,
        content: this.postDetail.content,
        image: this.postDetail.image,
        status: this.postDetail.status,
      });

    }
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onActivateUser(status) {
    status = !status;
    const statusMessage = status ? 'Are you sure to make Active?' : 'Are you sure to make In-Active?';
    const actiondata = this.confirmDialog.OnShowPopup(statusMessage);
    if (actiondata) {


      this.onAction(this.postDetail.id, 'status', status);
    }
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onDeleteUser() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.onAction(this.postDetail.id, 'isDeleted', 1);
    }
  }

  onAction(id, action, status) {
    this.spinner.show();
    this.api.onPostStatusUpdate(id, action, status).subscribe(data => {

      this.api.actionMessage.next({ type: 'success', message: 'User Deleted Successfully!' });

      if (action === 'isDeleted') {
        this.router.navigate(['/post_list']);
      } else {
        this.postDetail.status = status;
      }
      this.spinner.hide();
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.router.navigate(['/post_list']);
        this.spinner.hide();
      },
      () => {
      });
  }




  fileProgress(evt: any) {
    this.fileData = evt.target.files[0] as File;
    this.preview();
    this.postForm.controls.image.setValue(this.fileData.name);

    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }
  preview() {
    this.imageUpdate = 1;
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (event) => {
      this.previewUrl = reader.result;
      console.log(this.previewUrl, ' this.previewUrl');
    };
  }


  onSubmit() {
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    console.log(this.postForm.value);
    if (this.postForm.valid) {
      this.spinner.show();
      let imageData = {};
      if (this.imageUpdate) {
        let fileName = `${timestamp}/${this.fileData.name}`;
        let mime: any = this.fileData.type;
        if (mime !== '') {
          mime = mime.split('/');
          if (mime[1] !== undefined) {
            mime = mime[1];
            if (mime !== '') {
            fileName =   `${timestamp}.${mime}`;
            }
          }
        }

        this.postForm.controls.image.setValue(`${fileName}`);
        imageData = {
          key: this.api.key,
          secret: this.api.secret,
          name: `${this.postForm.controls.image.value}`,
          filename: `posts/${this.postForm.controls.image.value}`,
          filedata: this.base64textString,
          countryId: this.api.countryId,
          bucket: this.api.bucketName
        };
      }
      const post = this.postForm.value;
      this.api.onCreatePost(post, imageData).subscribe(finalgroupData => {
        let message = 'Post Added successfully.';
        let type = 'success';
        if (finalgroupData.affectedRows) {
          if (!this.isAdd) {
            message = 'Post updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/post_list']);
        this.spinner.hide();
      },
        error => { },
        () => { this.spinner.hide(); }

      );

    }

  }

  nameuploader(UpdaetdFile) {
    this.base64textString = UpdaetdFile;
  }

  onGetTypeList(type) {
    this.postForm.patchValue({ type: type.target.value });
    const typeName = type.target.value;

    this.onGetTypeListApi(typeName);

  }

  onGetTypeListApi(typeName) {
    this.typeName = 'Group';
    if (typeName === 'topic') {
      this.typeName = 'Topic';
      typeName = 'topics';
    }
    if (typeName === 'group') {
      typeName = 'groups';
    }
    if (typeName === 'event') {
      this.typeName = 'Event';
      typeName = 'events';
    }
    this.spinner.show();
    this.api.getListingTypeList(typeName).subscribe(typeList => {
      this.typeIdList = typeList;
    },
      error => { },
      () => { this.spinner.hide(); }

    );

  }

  onGetTypeId(typeId) {
    this.postForm.patchValue({ typeId: typeId.target.value });
  }

  ngOnDestroy() {
    this.api.postDetail = [];

  }
}
