import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService as AuthGuard } from './services/auth.service';

import { MainComponent } from '../app/main/main.component';
import { DashboardComponent } from '../app/pages/dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { UserListComponent } from '../app/pages/user/user-list/user-list.component';
// tslint:disable-next-line: max-line-length
import { UserInterestSuggestionListComponent } from '../app/pages/user/user-interest-suggestion-list/user-interest-suggestion-list.component';
import { AddEditComponent } from '../app/pages/user/add-edit/add-edit.component';
import { TopicListComponent } from '../app/pages/topic/topic-list/topic-list.component';
import { TopicAddEditComponent } from '../app/pages/topic/topic-add-edit/topic-add-edit.component';
import { PostListComponent } from '../app/pages/posts/post-list/post-list.component';
import { AddEditPostComponent } from '../app/pages/posts/addedit-post/addedit-post.component';
import { ViewPollResponseComponent } from '../app/pages/polls/view-poll-response/view-poll-response.component';
import { ReportListComponent } from '../app/pages/report/report-list/report-list.component';
import { ViewReportComponent } from '../app/pages/report/view-report/view-report.component';
import { GroupListComponent } from '../app/pages/group/group-list/group-list.component';
import { GroupRequestListComponent } from '../app/pages/group/group-request-list/group-request-list.component';
import { GroupAddEditComponent } from '../app/pages/group/group-add-edit/group-add-edit.component';
import { CommentListComponent } from '../app/pages/comment/comment-list/comment-list.component';
import { ViewCommentComponent } from '../app/pages/comment/view-comment/view-comment.component';
import { PollListComponent } from '../app/pages/polls/poll-list/poll-list.component';
import { PageListComponent } from '../app/pages/pages/page-list/page-list.component';
import { AddEditPageComponent } from '../app/pages/pages/addedit-page/addedit-page.component';
import { AddEditPollComponent } from '../app/pages/polls/addedit-poll/addedit-poll.component';
import { EventListComponent } from '../app/pages/event/event-list/event-list.component';
import { EventAddEditComponent } from '../app/pages/event/event-add-edit/event-add-edit.component';
import { RoleListComponent } from '../app/pages/role/role-list/role-list.component';
import { AddEditRoleComponent } from '../app/pages/role/add-edit-role/add-edit-role.component';
import { EditRoleCapabilityComponent } from '../app/pages/role/edit-role-capability/edit-role-capability.component';
import { AdsComponent } from './pages/ad-post/ads-list/ads.component';
import { AdsAddEditComponent } from './pages/ad-post/ads-add-edit/ads-add-edit.component';





const routes: Routes = [{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
{ path: 'mainarea', redirectTo: '/dashboard', pathMatch: 'full' },
{ path: 'mainarea', component: MainComponent, pathMatch: 'full' },
{ path: 'login', component: LoginComponent, pathMatch: 'full' },
{path: 'logout', component: LogoutComponent , pathMatch: 'full'},
{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
{path: 'user_list', component: UserListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'user_interest_suggestion_list', component: UserInterestSuggestionListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'user_list/member/:id/:id2/:id3', component: UserListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'user_list/:id/:id2/:id3', component: UserListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'user_list/edit/:id', component: AddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'topic_list/edit/:id', component: TopicAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'topic_list', component: TopicListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'topic_list/add', component: TopicAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'post_list', component: PostListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'post_list/:id/:id2/:id3', component: PostListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'post_list/:id', component: AddEditPostComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'post_add', component: AddEditPostComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'report_list', component: ReportListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'report_list/:id', component: ReportListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'report_list/view/:id', component: ViewReportComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'group_list', component: GroupListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'group_request_list', component: GroupRequestListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'group_list/edit/:id', component: GroupAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'group_list/add', component: GroupAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'comment_list', component: CommentListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'comment_list/:id', component: CommentListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'comment_list/view/:id', component: ViewCommentComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'page_list', component: PageListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'page_list/:id', component: AddEditPageComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'poll_list', component: PollListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'poll_list/:id', component: AddEditPollComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'poll_list/:id/:id2/:id3', component: PollListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'poll_list/:id', component: AddEditPollComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'poll_add', component: AddEditPollComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'poll_list/:id/:id2/poll_response/:id3', component: ViewPollResponseComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'event_list', component: EventListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'event_list/edit', component: EventAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'event_list/add', component: EventAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'role_list', component: RoleListComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'role_list/edit', component: AddEditRoleComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'role_list/add', component: AddEditRoleComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{path: 'role_list/capability/:id', component: EditRoleCapabilityComponent, pathMatch: 'full', canActivate: [AuthGuard]},
{ path: 'app-ads', component: AdsComponent, pathMatch:'full', canActivate:[AuthGuard]},
{path:'app-ads/edit',component:AdsAddEditComponent,pathMatch:'full',canActivate:[AuthGuard]},
{path:'app-ads/add',component:AdsAddEditComponent,pathMatch:'full',canActivate:[AuthGuard]},
{ path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
