import { Component, OnInit, AfterContentInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BarseltreffServiceProvider } from '../../services/barseltreff-service-provider.service';
import { ApiserviceService } from '../../services/api/apiservice.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterContentInit {
  loginForm: FormGroup;
  errorMessage2: any;
  isSubmited = false;
  constructor(private fb: FormBuilder, private router: Router, private auth: AuthService,
              private barseltreffServiceProvider: BarseltreffServiceProvider, private api: ApiserviceService,
              private spinner: NgxSpinnerService) {



  }
  ngAfterContentInit() {

  }
  ngOnInit() {
    this.auth.messageAuth.subscribe((errordata) => {
      this.errorMessage2 = errordata;
    });

    this.loginForm = this.fb.group({

      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),

    });


  }



  onSubmit() {
    this.isSubmited = true;
    if (this.loginForm.valid) {
      this.spinner.show();
      this.errorMessage2 = '';
      this.barseltreffServiceProvider.authenticate(this.loginForm.controls.email.value, this.loginForm.controls.password.value)
        .then((response: any) => {
          this.router.navigate(['/dashboard']);
          const JWT = response.getIdToken().getJwtToken();
          this.spinner.hide();
          localStorage.setItem('token', JWT);
          localStorage.setItem('responseData', response);
          localStorage.setItem('userPhone', this.loginForm.controls.email.value);
        }, (error: any) => {
          this.spinner.hide();
          this.errorMessage2 = 'Phone or password is incorrect.';
        });
    }
  }


}
