import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';



import { from } from 'rxjs';
@Component({
  selector: 'app-topic-add-edit',
  templateUrl: './topic-add-edit.component.html',
  styleUrls: ['./topic-add-edit.component.css']
})
export class TopicAddEditComponent implements OnInit {
  uploader: any;
  topicId = 0;
  topicData: any = {};
  profileImage = '';
  isEdit = 0;
  topicForm: FormGroup;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  base64textString = '';
  userProfileData: any = {};
  imageUpdate = 0;
  isSubmited = false;
  notifier: any = '';
  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService,
              private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder
  ) {
    this.notifier = notifierService;
    this.onDefineFormField();
    const userProfileData: any = localStorage.getItem('userProfileData');
    this.userProfileData = JSON.parse(userProfileData);
  }

  ngOnInit() {
    // this.uploader = new FileUploader({url: 'api/files', autoUpload: true});
    this.route.params.subscribe((params: Params) => {

      if (typeof params.id !== 'undefined') {
        this.isEdit = 1;
        this.spinner.show();
        this.topicId = params.id;
        this.api.getTopicDetail(this.topicId).subscribe(topicData => {
          this.topicData = topicData;
          if (topicData.image_name !== null && topicData.image_name !== '') {

            this.profileImage = `${this.api.fileUrl}/topics/${topicData.image_name}`;
            this.previewUrl = this.profileImage;
          }

          this.topicForm.patchValue({
            id: this.topicId,
            name: topicData.name,
            image_name: topicData.image_name,
            is_featured: topicData.is_featured
          });

        }, error => { this.spinner.hide(); }, () => { this.spinner.hide(); });
      }
    },
      error => { this.spinner.hide(); },
      () => { this.spinner.hide(); }
    );
  }

  onDefineFormField() {
    this.topicForm = this.fb.group({

      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      image_name: new FormControl(null),
      is_featured: new FormControl(false)

    });
  }



  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onActivateUser() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onDeleteTopic() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteTopic(this.topicData.id).subscribe(data => {

        this.api.actionMessage.next({ type: 'success', message: 'Topic Deleted Successfully!' });

        this.router.navigate(['/topic_list']);
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Topic not Deleted! Something went wrong.' });
          this.router.navigate(['/topic_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  fileProgress(evt: any) {
    this.fileData = evt.target.files[0] as File;
    this.preview();
    this.topicForm.controls.image_name.setValue(this.fileData.name);

    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }
  preview() {
    this.imageUpdate = 1;
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (event) => {
      this.previewUrl = reader.result;
    };
  }


  onSubmit() {
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    console.log(this.topicForm.value);
    if (this.topicForm.valid) {
      this.spinner.show();
      let imageData = {};
      if (this.imageUpdate) {
        this.topicForm.controls.image_name.setValue(`${timestamp}/${this.fileData.name}`);
        imageData = {
          key: this.api.key,
          secret: this.api.secret,
          name: `${this.topicForm.controls.image_name.value}`,
          filename: `topics/${this.topicForm.controls.image_name.value}`,
          filedata: this.base64textString,
          countryId: this.api.countryId,
          bucket: this.api.bucketName
        };
      }
      const topic = this.topicForm.value;
      const finalTopicData = {
        topic,
        imageData
      };
      // tslint:disable-next-line: no-shadowed-variable
      this.api.onCreateTopic(topic, imageData).subscribe(finalTopicData => {
        let message = 'Topic Added successfully.';
        let type = 'success';
        if (finalTopicData.affectedRows) {
          if (this.isEdit) {
            message = 'Topic updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/topic_list']);
        this.spinner.hide();
      },
        error => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
        () => { this.spinner.hide(); }

      );

    }

  }

  nameuploader(UpdaetdFile) {
    this.base64textString = UpdaetdFile;
  }


}
