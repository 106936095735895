import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';


import { from } from 'rxjs';
@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.css'],
  providers: [DatePipe]
})
export class ViewPostComponent implements OnInit {
  userId = 0;
  profileImage = '';
  postDetail: any = [];
  pollsList: any = [];
  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService,
              private confirmDialog: ConfirmationDialogComponent
  ) {}

  ngOnInit() {
    this.postDetail = this.api.postDetail;
    if (this.postDetail.length === 0) {
      this.router.navigate(['post_list']);
    } else {
      if (this.postDetail.image !== '' && this.postDetail.image !== null) {
        this.profileImage = `${this.api.fileUrl}/posts/${this.postDetail.image}`;
      }
    }
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onActivateUser(status) {
    status = !status;
    const statusMessage = status ? 'Are you sure to make Active?' : 'Are you sure to make In-Active?';
    const actiondata = this.confirmDialog.OnShowPopup(statusMessage);
    if (actiondata) {


      this.onAction(this.postDetail.id, 'status', status);
    }
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onDeleteUser() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.onAction(this.postDetail.id, 'isDeleted', 1);
    }
  }

  onAction(id, action, status) {
    this.spinner.show();
    this.api.onPostStatusUpdate(id, action, status).subscribe(data => {

      this.api.actionMessage.next({ type: 'success', message: 'User Deleted Successfully!' });

      if (action === 'isDeleted') {
        this.router.navigate(['/post_list']);
      } else {
        this.postDetail.status = status;
      }
      this.spinner.hide();
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.router.navigate(['/post_list']);
        this.spinner.hide();
      },
      () => {
      });
  }




}
