import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';


import { from } from 'rxjs';
@Component({
  selector: 'app-view-comment',
  templateUrl: './view-comment.component.html',
  styleUrls: ['./view-comment.component.css'],
  providers: [DatePipe]
})
export class ViewCommentComponent implements OnInit {
  userId = 0;
  profileImage = '';
  postDetail: any = [];
  pollsList: any = [];
  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService,
              private confirmDialog: ConfirmationDialogComponent
  ) { }

  ngOnInit() {
    this.postDetail = this.api.postDetail;

    if (this.postDetail.length === 0) {
      this.router.navigate(['comment_list']);
    } else {
      if (this.postDetail.image !== '' && this.postDetail.image !== null) {
        this.profileImage = `${this.api.fileUrl}/posts/${this.postDetail.image}`;
      }

    }
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onStatusChange(status) {
    this.onUpdateStatus('userComment', status);
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }


  onAction(id, status) {
    this.spinner.show();
    this.api.onReportStatusUpdate(id, status).subscribe(data => {
      this.postDetail.Actionstatus = status;
      this.spinner.hide();
    },
      (error) => {
        this.router.navigate(['/comment_list']);
        this.spinner.hide();
      },
      () => {
      });
  }


  onUpdateStatus(type, status) {
    status = (status === 0) ? 1 : 0;
    // const type = 'report';
    // const status = 1;
    const id = this.postDetail.id;
    this.api.onUpdateStatus(id, type, status).subscribe(data => {
      this.postDetail.status = status;
      this.spinner.hide();
    },
      (error) => {
        this.router.navigate(['/comment_list']);
        this.spinner.hide();
      },
      () => {
      });

  }

}
