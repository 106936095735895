// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://a82kzv3hnb.execute-api.eu-west-1.amazonaws.com/dev/',
  appName: 'barseltreff-dev',
  bucketName: 'barseltreff-uploads-dev',
  semanticVersion: '0.0.1',
  version: '000001',
  cdnUrl: 'https://barseltreff-uploads-dev.s3-eu-west-1.amazonaws.com',
  aws: {
    key: 'AKIA4UZ7I56T53TH7HYO',
    secret: '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',
    countryId: 'Europe',
    region: 'eu-west-1',
    identityPoolId: 'us-east-2:189c28e4-5453-44e6-a101-2e1675d26fb9',
    userPoolId: 'eu-west-1_2eCZqYRqs',
    poolClientId: '31g0ngjg1jcqg51ekdbnts4acm'
    // identityPoolId: 'us-east-2:88af04bd-ed3c-4099-be38-046a3cba8461',
    // userPoolId: 'eu-west-1_rfHpqdO4L',
    // poolClientId: '6ic7p3h8d62klrrqncvo21q84j'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
