import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';


import { from } from 'rxjs';
@Component({
  selector: 'app-addedit-page',
  templateUrl: './addedit-page.component.html',
  styleUrls: ['./addedit-page.component.css'],
  providers: [DatePipe]
})
export class AddEditPageComponent implements OnInit, OnDestroy {
  userId = 0;
  profileImage = '';
  postDetail: any = [];
  pollsList: any = [];
  isAdd = 0;
  postForm: FormGroup;
  base64textString: any = '';
  fileData: File = null;
  imageUpdate = 0;
  isSubmited = false;
  previewUrl: any = null;
  typeIdList: any = [];
  typeName = '';

  constructor(private route: ActivatedRoute, private api: ApiserviceService, private router: Router,
              private spinner: NgxSpinnerService, private notifierService: NotifierService,
              private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder
  ) {

    this.onDefineFormField();
  }
  onDefineFormField() {

    this.postForm = this.fb.group({

      id: new FormControl(null),
      title: new FormControl(null, [Validators.required]),
      content: new FormControl(null, [Validators.required]),
    });
  }
  ngOnInit() {
    this.postDetail = this.api.postDetail;
    if (this.postDetail.length === 0) {
      this.router.navigate(['page_list']);
      this.isAdd = 1;
    } else {
      if (this.postDetail.image !== '' && this.postDetail.image !== null) {
        this.profileImage = `${this.api.fileUrl}/posts/${this.postDetail.image}`;
        this.previewUrl = this.profileImage;

      }
      //this.onGetTypeListApi(this.postDetail.type);
      this.postForm.patchValue({
        id: this.postDetail.id,
        title: this.postDetail.title,
        content: this.postDetail.content
      });

    }
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onActivateUser(status) {
    status = !status;
    const statusMessage = status ? 'Are you sure to make Active?' : 'Are you sure to make In-Active?';
    const actiondata = this.confirmDialog.OnShowPopup(statusMessage);
    if (actiondata) {


      this.onAction(this.postDetail.id, 'status', status);
    }
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onDeleteUser() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.onAction(this.postDetail.id, 'isDeleted', 1);
    }
  }

  onAction(id, action, status) {
    this.spinner.show();
    this.api.onPostStatusUpdate(id, action, status).subscribe(data => {

      this.api.actionMessage.next({ type: 'success', message: 'User Deleted Successfully!' });

      if (action === 'isDeleted') {
        this.router.navigate(['/post_list']);
      } else {
        this.postDetail.status = status;
      }
      this.spinner.hide();
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.router.navigate(['/post_list']);
        this.spinner.hide();
      },
      () => {
      });
  }


  onSubmit() {
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    console.log(this.postForm.value);
    if (this.postForm.valid) {
      this.spinner.show();
      const post = this.postForm.value;
      this.api.onEditPaget(post).subscribe(finalgroupData => {
        let message = 'Page Added successfully.';
        let type = 'success';
        if (finalgroupData.affectedRows) {
          if (!this.isAdd) {
            message = 'Page updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/page_list']);
        this.spinner.hide();
      },
        error => { },
        () => { this.spinner.hide(); }

      );

    }

  }

  nameuploader(UpdaetdFile) {
    this.base64textString = UpdaetdFile;
  }

  onGetTypeList(type) {
    this.postForm.patchValue({ type: type.target.value });
    const typeName = type.target.value;

    this.onGetTypeListApi(typeName);

  }

  onGetTypeListApi(typeName) {
    this.typeName = 'Group';
    if (typeName === 'topic') {
      this.typeName = 'Topic';
      typeName = 'topics';
    }
    if (typeName === 'group') {
      typeName = 'groups';
    }
    if (typeName === 'event') {
      this.typeName = 'Event';
      typeName = 'events';
    }
    this.spinner.show();
    this.api.getListingTypeList(typeName).subscribe(typeList => {
      this.typeIdList = typeList;
    },
      error => { },
      () => { this.spinner.hide(); }

    );

  }

  onGetTypeId(typeId) {
    this.postForm.patchValue({ typeId: typeId.target.value });
  }

  ngOnDestroy() {
    this.api.postDetail = [];

  }
}
