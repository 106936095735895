import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';



import { from } from 'rxjs';
@Component({
  selector: 'app-edit-role-capability',
  templateUrl: './edit-role-capability.component.html',
  styleUrls: ['./edit-role-capability.component.css']
})
export class EditRoleCapabilityComponent implements OnInit {
  uploader: any;
  topicId = 0;
  topicData: any = {};
  profileImage = '';
  isEdit = 0;
  userProfileData: any = {};
  roleId = 0;
  isSubmited = false;
  notifier: any = '';
  postDetail: any = [];
  capabilities = [];
  roleData = [];
  constructor(
    private route: ActivatedRoute,
    private api: ApiserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private notifierService: NotifierService,
    private confirmDialog: ConfirmationDialogComponent,
    private fb: FormBuilder
  ) {
    this.notifier = notifierService;

    const userProfileData: any = localStorage.getItem('userProfileData');
    this.userProfileData = JSON.parse(userProfileData);
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (typeof params.id !== 'undefined') {
        this.roleId = params.id;
        this.onGetCapacities(params.id);
      }
    });

  }

  onGetCapacities(paramsid) {
    this.spinner.show();
    this.api.onGetCapacities(paramsid).subscribe(capabilities => {
      console.log(capabilities);
      // this.capabilities = capabilities.capacities;
      const roleData = capabilities.roleData;
      if (capabilities.capacities.length > 0) {
        for (const capability of capabilities.capacities) {
          let create = false;
          let edit = false;
          let deleteData = false;
          let visibility = false;

          if (roleData.length > 0) {
            for (const role of roleData) {
              if (role.capability === capability.id) {
                create = role.create ? true : false;
                edit = role.edit ? true : false;
                deleteData = role.delete ? true : false;
                visibility = role.visibility ? true : false;
              }
            }
          }


          const dataCapbility = {
            roleId: paramsid,
            capability: capability.id,
            create,
            edit,
            delete: deleteData,
            visibility
          };

          // this.roleData.push(dataCapbility);
          capability.roleData = dataCapbility;
          this.capabilities.push(capability);
        }
        console.log(this.capabilities);
      }
      console.log(this.capabilities, 'this.capabilities');
      // if (capabilities.length > 0) {
      //   for (const capability of capabilities) {
      //     this.roleForm.addControl(capability.slug, this.fb.array([
      //       this.fb.group({
      //         create: ['', Validators.required]
      //       }),
      //       this.fb.group({
      //         edit: ['', Validators.required]
      //       }),
      //       this.fb.group({
      //         delete: ['', Validators.required]
      //       }),
      //       this.fb.group({
      //         view: ['', Validators.required]
      //       }),
      //       this.fb.group({
      //         visibility: ['', Validators.required]
      //       })
      //     ]));
      //     // const control = this.roleForm.controls.capability['slug'] as FormArray;
      //     // control.push(this.fb.group({ optionData: [''] }));
      //   }
      //   console.log(this.roleForm.controls);
      //   console.log(this.roleForm.value);
      // }
    },
      error => { this.spinner.hide(); },
      () => { this.spinner.hide(); }

    );
  }





  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }
  onActivateUser() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }
  onDeleteRole() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteRole(this.postDetail.id).subscribe(data => {
        if (data[1].status === false) {
          alert(`${data[0].assignedUsers} user(s) are in this role. Please remove roles from user(s) to delete role!`);
        } else {
          this.api.actionMessage.next({ type: 'success', message: 'Role Deleted Successfully!' });
          this.router.navigate(['/role_list']);
        }

        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Role not Deleted! Something went wrong.' });
          this.router.navigate(['/role_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }


  onSubmit() {
    this.roleData = [];
    if (this.capabilities.length > 0) {
      for (const roleData of this.capabilities) {
        this.roleData.push(roleData.roleData);
      }
    }
    console.log(this.roleData);
    const payLoad = {
      roleId: this.roleId,
      roleData: this.roleData
    };
    this.api.updateCapacity(payLoad).subscribe(finalTopicData => {
      let message = '';
      let type = 'success';
      if (finalTopicData.affectedRows) {

        message = 'Role Capability updated successfully.';

      } else {
        message = 'Something went wrong!';
        type = 'error';
      }
      this.api.actionMessage.next({ type, message });
      this.router.navigate(['/role_list']);
      this.spinner.hide();
    },
      error => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
      () => { this.spinner.hide(); }

    );


  }




}
