import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';


import { from } from 'rxjs';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.css']
})
export class AddEditComponent implements OnInit {
  userId = 0;
  userData: any = {};
  profileImage = '';
  rolesList: any = [];
  roleName = '';
  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private api: ApiserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private notifierService: NotifierService,
    private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder
  ) {
    this.spinner.show();

    this.userForm = this.fb.group({
      isPhoneVerified: new FormControl(false)
    });
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      if (typeof params.id !== 'undefined') {
        this.userId = params.id;
        this.api.getUser(this.api.userEditData.email).subscribe(data => {
          this.userData = data;
          console.log(data, 'userData');
          this.userForm.patchValue({
            isPhoneVerified: this.userData.isPhoneVerified
          });
          if (data.profilePicture !== null && data.profilePicture !== '') {
            this.profileImage = `${this.api.fileUrl}/users/${data.uuid}/${data.profilePicture}`;
          }
          if (this.api.userEditData.id === `user_list/edit/${this.userId}`) {
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.router.navigate(['user_list']);
          }
        }, error => {
          console.log(error, 'error');
        }, () => { });


      }
    });
  }

  onNotificationCall(type = 'success', message = 'Details loaded') {
    this.notifierService.show({ type, message });
  }

  onActivateUser() {

    const statusData = (this.userData.isBlocked === 1) ? 0 : 1;
    const message = (this.userData.isBlocked === 1) ? 'Are you sure to unblock user?' : 'Are you sure to block user?';

    const payLoad = {
      id: this.userData.id,
      status: statusData
    };
    const actiondata = this.confirmDialog.OnShowPopup(message);
    if (actiondata) {
      this.spinner.show();
      this.api.blockUnblockUser(payLoad).subscribe(data => {

        this.api.actionMessage.next({ type: 'success', message: 'User Deleted Successfully!' });

        this.router.navigate(['/user_list']);
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
          this.router.navigate(['/user_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  onMembershipChange() {
    this.onNotificationCall('warning', 'Functionality under development!');
  }

  onDeleteUser() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.onCloseAccount('settings CloseAccount', this.userData.id).subscribe(result => {

        this.api.actionMessage.next({ type: 'success', message: 'User Deleted Successfully!' });

        this.router.navigate(['/user_list']);
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
          this.router.navigate(['/user_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  onVerifyUser(ev) {
    this.spinner.show();
    this.userData.isphoneVerified = parseInt(this.userForm.value.isPhoneVerified, 10);
    const data = {
      user: this.userData
    };

    this.api.onVerifyAccount('settings VerifyUser', this.userData.id, data).subscribe(result => {

      this.api.actionMessage.next({ type: 'success', message: 'User Verified Successfully!' });

      this.router.navigate(['/user_list']);
      this.spinner.hide();
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Verified! Something went wrong.' });
        this.router.navigate(['/user_list']);
        this.spinner.hide();
      },
      () => {
      });
  }
}
