import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { ApiserviceService } from '../services/api/apiservice.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  currentRoute: string;
  userProfileData: any = {};
  constructor(private route: ActivatedRoute, private router: Router, private location: Location, private api: ApiserviceService) { }

  ngOnInit() {

    this.router.events.subscribe(val => {
      if (this.location.path !== null) {
        this.currentRoute = this.location.path();
      } else {
        this.currentRoute = 'dashboard';
      }


    });



    this.userProfileData =  localStorage.getItem('userProfileData');

    if ( this.userProfileData !== '' && this.userProfileData !== null ) {
      this.userProfileData = JSON.parse(this.userProfileData);
      this.api.userInformation.next(this.userProfileData);

    } else {
      this.onGetUserDetails();
    }
  }

  onGetUserDetails() {
    const email =  localStorage.getItem('userPhone');
    this.api.getUser(email).subscribe(data => {
        this.userProfileData = data;
        localStorage.setItem('userProfileData', JSON.stringify(data));
        this.api.userInformation.next(data);
      });
        }

}
