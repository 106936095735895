import { Component, OnInit, Renderer, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css'],
  providers: [DatePipe]
})
export class GroupListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';

  // tslint:disable-next-line: deprecation
  constructor(private api: ApiserviceService, private location: Location, private router: Router, renderer: Renderer,
              private notifierService: NotifierService, private spinner: NgxSpinnerService,
              private confirmDialog: ConfirmationDialogComponent) {
    this.notifier = notifierService;
    this.elmentclear = renderer.listen('document', 'click', (event) => {

      // Do something with 'event'
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-edit`) {
        if (event.target.parentElement.id === 'group_list') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const formData = this.tableWidget.rows().data();
          $.each(formData, (key, value) => {
            // tslint:disable-next-line: radix
            if (value.id === parseInt(id)) {
              this.api.postDetail = value;
            }
          });
          this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);

        }
      }
      if (event.target.className === `glyphicon glyphicon-trash`) {
        if (event.target.parentElement.id === 'topic_delete') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const id = event.target.parentElement.attributes[3].nodeValue;

          this.onDeleteGroup(id);
        }
      }
      if (event.target.className === `glyphicon glyphicon-file`) {
        if (event.target.parentElement.id === 'view_posts') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const name = event.target.parentElement.attributes[4].nodeValue;
          this.router.navigate(['post_list/group/' + id + '/' + name]);
        }
      }

      if (event.target.className === `glyphicon glyphicon-stats`) {
        if (event.target.parentElement.id === 'view_polls') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const name = event.target.parentElement.attributes[4].nodeValue;
          this.router.navigate(['poll_list/group/' + id + '/' + name]);
        }
      }


      if (event.target.className === `cursorpointer user_list_from_group`) {
        if (event.target.id === 'user_list_from_group') {
          const id = event.target.attributes[1].nodeValue;
          const groupName = event.target.attributes[2].nodeValue;
          this.router.navigate(['user_list/member/group/' + id + '/' + groupName]);

        }
      }

    });

  }

  OnShowPopup() {
    const actionVal = confirm('Are you sure to delete?');
    return actionVal;
  }
  onDeleteGroup(id) {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteGroup(id).subscribe(data => {

        this.api.actionMessage.next({ type: 'success', message: 'Group Deleted Successfully!' });

        this.tableWidget.draw();
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Group not Deleted! Something went wrong.' });

          this.spinner.hide();
        },
        () => {
        });
    }
  }

  ngOnInit() {
    //   if (typeof this.notifier.notify === 'function') {
    //   this.api.actionMessage.subscribe(messageData => {
    //     this.notifier.notify(messageData.type, messageData.message);
    //     this.notifier = '';
    //   });
    // }
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}group/getgrouplist`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'name' },
        { data: 'is_featured' },
        { data: 'is_private' },
        { data: 'membercount' },
        { data: 'created_date' },
        { data: 'options' }
      ],

      columnDefs: [

        {
          targets: 2,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            let isFeatured = 'No';
            if (data === 1) {
              isFeatured = 'Yes';
            }
            return isFeatured;
          }
        },
        {
          targets: 3,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            let isFeatured = 'No';
            if (data === 1) {
              isFeatured = 'Yes';
            }
            return isFeatured;
          }
        },
        {
          targets: 4,
          searchable: false,
          orderable: true,
          className: 'dt-body-center',
          render(data, type, full, meta) {

            const value = '<a id="user_list_from_group" groupId="' + full.id +
              '"  groupName="' + full.name + '"  class="cursorpointer user_list_from_group" style="cursor: pointer">' + data + '</a>';
            return value;
          }
        },
        {
          targets: 5,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        },
        {
          targets: 6,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            return '<a id="group_list" data="group_list/edit/' + full.id + '"'
              + 'title="EDIT" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-edit"></span>'
              + '</a><a id="view_posts" data="post_list/topics/' + full.id + '"'
              + 'title="View Posts" dataArray="' + full.id + '" name="'
              + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-file"></span>'
              + '</a><a id="view_polls" data="post_list/topics/' + full.id + '"'
              + 'title="View Polls" dataArray="' + full.id + '" name="'
              + full.name + '" class="action_buttons"><span class="glyphicon glyphicon-stats"></span>'
              + '</a><a id="topic_delete" data="group_list/edit/' + full.id + '"'
              + 'title="Delete" dataArray="' + full.id +
              '" class="action_buttons"><span style="color:red" class="glyphicon glyphicon-trash"></span>'
              + '</a>';
          }
        }],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[0, 'desc']]

    });
  }


  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }


  }

  onAddTopic() {

    this.router.navigate(['topic_list/add']);
  }
}
