import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-user-interest-suggestion-list',
  templateUrl: './user-interest-suggestion-list.component.html',
  styleUrls: ['./user-interest-suggestion-list.component.css'],
  providers: [DatePipe]
})
export class UserInterestSuggestionListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';
  type = '';
  typeName = '';
  typeId: any = 0;

  // tslint:disable-next-line: deprecation
  constructor(
    private api: ApiserviceService,
    private location: Location,
    private router: Router,
    public renderer: Renderer2,
    public notifierService: NotifierService,
    public route: ActivatedRoute
  ) {
    this.notifier = notifierService;

    this.elmentclear = renderer.listen('document', 'click', (event) => {
      // Do something with 'event'
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-edit`) {
        if (event.target.parentElement.id === 'user_edit') {

          // const attrData =  JSON.parse(event.target.parentElement.attributes[3]);
          // console.log(event.target.parentElement.attributes[3].nodeValue, 'attrData');
          const email = event.target.parentElement.attributes[3].nodeValue;
          const id = event.target.parentElement.attributes[1].nodeValue;
          this.api.userEditData = { email, id };
          this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);
        }
      }


    });

    route.paramMap.subscribe(params => {
      this.typeId = params.get('id2');
      this.typeName = params.get('id3');
      this.type = params.get('id');
      console.log(this.typeName, 'this.typeName', this.type);
    });
  }

  ngOnInit() {
    // this.notifier.notify("success", "You are awesome! I mean it!");


    // this.api.actionMessage.subscribe(messageData => {
    //   this.notifier.notify(messageData.type, messageData.message);
    // });
    // this.notifier = '';
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.type = 'interest';
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}user/actions/getAllUser`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          const type = this.type;
          const typeId = this.typeId;
          d.postTypeFilter = { type, typeId };
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'name' },
        { data: 'phone' },
        { data: 'categorySuggestion' }
      ],

      columnDefs: [
        //          {
        //    targets: 0,
        //    searchable: false,
        //    orderable: false,
        //    className: 'dt-body-center',
        //    render(data, type, full, meta) {
        //        return '<input class="innercheck" type="checkbox" name="id[]" value="'
        //           + $('<div/>').text(data).html() + '">';
        //    }
        // },
      ],
      order: [[0, 'desc']]

    });
  }

  onBack() {
    this.location.back();
  }

  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }

  }
}
