import { Component, OnChanges } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ApiserviceService } from './services/api/apiservice.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnChanges {
  title = 'barseltreff-admin';
  authcheck = false;

  constructor(private auth: AuthService, private api: ApiserviceService) {
    this.auth.newchange.subscribe((data) => {

      this.authcheck = data;
    });



    setInterval(() => {
        this.onCallFunction();

    }, 60000);
  }

  onCallFunction() {
    this.api.onGetNotification().subscribe(data => {
      this.api.notificationData.next(data);
    },
      (error) => {
      },
      () => {
      });
  }

  ngOnChanges() {

  }
}
