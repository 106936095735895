import { Component, OnInit, Renderer, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Location } from '@angular/common';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.css'],
  providers: [DatePipe]
})
export class CommentListComponent implements OnInit, OnDestroy {
  pagination: number;
  limit: number;
  public tableWidget: any;
  public elmentclear;
  public dataArray: any = [];
  notifier: any = '';
  postId = '';

  // tslint:disable-next-line: deprecation
  constructor(private api: ApiserviceService, private location: Location, private router: Router, renderer: Renderer,
              private notifierService: NotifierService, private spinner: NgxSpinnerService,
              private confirmDialog: ConfirmationDialogComponent, private route: ActivatedRoute) {
    this.notifier = notifierService;

    this.elmentclear = renderer.listen('document', 'click', (event) => {
      // Do something with 'event'
      event.preventDefault();
      if (event.target.className === `glyphicon glyphicon-eye-open`) {
        if (event.target.parentElement.id === 'comment_view') {
          const formData = this.tableWidget.rows().data();
          const id = event.target.parentElement.attributes[3].nodeValue;
          $.each(formData, (key, value) => {
            // tslint:disable-next-line: radix
            if (value.id === parseInt(id)) {
              this.api.postDetail = value;
            }
          });
          this.router.navigate([event.target.parentElement.attributes[1].nodeValue]);
        }
      }
      if (event.target.className === `glyphicon glyphicon-file`) {
        if (event.target.parentElement.id === 'post_view') {
          // const formData = this.tableWidget.rows().data();
          const id = event.target.parentElement.attributes[3].nodeValue;
          console.log(id, 'id');
          this.onGetPostDetail(id, 'poll_list');
        }
      }

      if (event.target.className === `glyphicon glyphicon-stats`) {
        if (event.target.parentElement.id === 'poll_list') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          console.log(id, 'id');
          this.onGetPostDetail(id, 'poll_list');
        }
      }

      if (event.target.className === `glyphicon glyphicon-exclamation-sign`) {
        if (event.target.parentElement.id === 'post_status') {
          const id = event.target.parentElement.attributes[3].nodeValue;
          const status = event.target.parentElement.attributes[2].nodeValue;
          this.onUpdateStatus(id, 'userComment', status);
        }
      }
    });

    this.route.paramMap.subscribe(params => {
      this.postId = params.get('id');

    });
  }

  onGetPostDetail(id, type) {
    this.spinner.show();
    this.api.onGetPostDetail(id).subscribe(data => {
      this.api.postDetail = data;
      this.spinner.hide();

      if (type === 'poll_list') {
        this.router.navigate(['/poll_list/' + id]);
      } else {
        this.router.navigate(['/post_list/' + id]);
      }
    },
      (error) => {
        this.api.actionMessage.next({ type: 'error', message: 'User not Deleted! Something went wrong.' });
        this.spinner.hide();
      },
      () => {
      });

  }


  ngOnInit() {
    this.initDatatable();
  }

  private initDatatable(): void {
    const token = localStorage.getItem('token');
    const exampleId: any = $('#example');
    this.tableWidget = exampleId.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${this.api.apiUrl}comment/list`,
        dataType: 'json',
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          appversion: this.api.version
        },
        data: ((d) => {
          const postId = this.postId;
          d.postTypeFilter = { postId };
          return JSON.stringify(d);
        }),
        error: (xhr) => {

          if (xhr.status === 0 || xhr.status === '0') {
            this.api.nonAuthRedirect();
          }
        }
      },
      columns: [
        // { data: 'select' },
        { data: 'id' },
        { data: 'postTitle' },
        { data: 'authorname' },
        { data: 'status' },
        { data: 'message' },
        { data: 'createdAt' },
        { data: 'options' }
      ],
      // oSearch: {"sSearch": "San Francisco"},
      columnDefs: [
        {
          targets: 3,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            let status = 'Unapproved';
            if (data === 1) {
              status = 'Approved';
            }
            return status;
          }
        },
        {
          targets: 4,
          render(data, type, row) {
            if (data === '' || data.length <= 60) {
              return data;
            } else {
              const shortText = data.substring(0, 60);
              const cleanStr = data.replace(/["']/g, '');
              return shortText + '...';
            }
          }
        },
        {
          targets: 5,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            const datePipe = new DatePipe('en-EU');
            const value = datePipe.transform(data, 'd/M/yyyy, h:mm a');
            return value;
          }
        },
        {
          targets: 6,
          searchable: false,
          orderable: false,
          className: 'dt-body-center',
          render(data, type, full, meta) {
            // return '<input class="innercheck" type="checkbox" name="id[]" value="'
            // + $('<div/>').text(data).html() + '">';

            let statusClass = 'span_red';
            let statusTitle = 'Mark as Approved';
            if (full.status === 1) {
              statusClass = '';
              statusTitle = 'Mark as Unapproved';
            }
            let icon = 'glyphicon-file';
            let postType = 'post_view';
            let title = 'View Post Detail';
            if (full.postTitleName === 'poll') {
              postType = 'poll_list';
              icon = 'glyphicon-stats';
              title = 'View Poll Detail';
            }
            return '<a id="comment_view" data="comment_list/view/' + full.id + '"'
              + 'title="View Details" dataArray="' + full.id + '" class="action_buttons"><span class="glyphicon glyphicon-eye-open"></span>'
              + '</a><a id="' + postType + '" data="' + postType + '/' + full.id + '"'
              + 'title="' + title + '" dataArray="' +
              full.postId + '" class="action_buttons"><span class="glyphicon ' + icon + '"></span>'
              + '</a><a id="post_status" data="post_list/' + full.id + '"'
              + 'status="' + full.status + '" dataArray="' + full.id + '" title="' + statusTitle + '"  class="action_buttons ' +
              statusClass + '"><span class="glyphicon glyphicon-exclamation-sign"></span>'
              + '</a>';
          }
        }
      ],
      select: {
        style: 'os',
        selector: 'td:first-child'
      },
      order: [[3, 'desc']]

    });
  }

  onBack() {
    this.location.back();
  }

  ngOnDestroy() {

    if (this.elmentclear) {
      this.elmentclear();
    }

  }



  onUpdateStatus(id, type, status) {
    status = (status === '0') ? 1 : 0;
    this.api.onUpdateStatus(id, type, status).subscribe(data => {
      this.tableWidget.draw();
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
      },
      () => {
      });

  }
}
