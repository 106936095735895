import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiserviceService } from '../../../services/api/apiservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../../../common/confirmation-dialog/confirmation-dialog.component';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';



import { from } from 'rxjs';
@Component({
  selector: 'app-group-add-edit',
  templateUrl: './group-add-edit.component.html',
  styleUrls: ['./group-add-edit.component.css']
})
export class GroupAddEditComponent implements OnInit {
  uploader: any;
  topicId = 0;
  groupData: any = {};
  profileImage = '';
  isEdit = 0;
  groupForm: FormGroup;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  base64textString = '';
  userProfileData: any = {};
  imageUpdate = 0;
  isSubmited = false;
  notifier: any = '';
  totalMember = 0;
  groupMembers = [];
  groupAdmin = [];
  constructor(
    private route: ActivatedRoute,
    private api: ApiserviceService,
    private router: Router,
    private spinner: NgxSpinnerService, private notifierService: NotifierService,
    private confirmDialog: ConfirmationDialogComponent, private fb: FormBuilder
  ) {
    this.notifier = notifierService;
    this.onDefineFormField();
    const userProfileData: any = localStorage.getItem('userProfileData');
    this.userProfileData = JSON.parse(userProfileData);
  }

  ngOnInit() {
    // this.uploader = new FileUploader({url: 'api/files', autoUpload: true});
    // this.route.params.subscribe((params: Params) => {

    //   if (typeof params.id !== 'undefined') {
    //     this.isEdit = 1;
    //     this.spinner.show();
    //     this.topicId = params.id;
    //     this.api.getTopicDetail(this.topicId).subscribe(groupData => {
    //       this.groupData = groupData;
    //       if (groupData.image_name !== null && groupData.image_name !== '') {

    //         this.profileImage = `${this.api.fileUrl}/topics/${groupData.image_name}`;
    //         this.previewUrl = this.profileImage;
    //       }

    //       this.groupForm.patchValue({
    //         id: this.topicId,
    //         name: groupData.name,
    //         image_name: groupData.image_name,
    //         is_featured: groupData.is_featured
    //       });

    //     }, error => { this.spinner.hide(); }, () => { this.spinner.hide(); });
    //   }
    // },
    //   error => { this.spinner.hide(); },
    //   () => { this.spinner.hide(); }
    // );
    this.route.params.subscribe((params: Params) => {
      console.log(params, 'params');
      if (typeof params.id !== 'undefined') {

        this.groupData = this.api.postDetail;
        if (this.groupData.length === 0) {
          this.isEdit = 0;
        } else {
          this.isEdit = 1;
          this.groupForm.patchValue({
            id: this.groupData.id,
            name: this.groupData.name,
            image_name: this.groupData.image_name,
            is_featured: this.groupData.is_featured,
            is_private: this.groupData.is_private
          });
          this.onGetMembersAdmin(this.groupData.id);
          this.totalMember = this.groupData.membercount;
          if (this.groupData.image_name !== '' && this.groupData.image_name !== null) {
            this.profileImage = `${this.api.fileUrl}/groups/${this.groupData.image_name}`;
            this.previewUrl = this.profileImage;
          }


        }


      }
    });


  }

  onViewMembers() {
    this.router.navigate(['user_list/member/group/' + this.groupData.id + '/' + this.groupData.name]);
  }

  onDefineFormField() {
    this.groupForm = this.fb.group({

      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      image_name: new FormControl(null),
      is_featured: new FormControl(false),
      is_private: new FormControl(false),
      group_admin: new FormControl([]),

    });
  }

  onDeleteGroup() {
    const actiondata = this.confirmDialog.OnShowPopup();
    if (actiondata) {
      this.spinner.show();
      this.api.deleteGroup(this.groupData.id).subscribe(data => {

        this.api.actionMessage.next({ type: 'success', message: 'Group Deleted Successfully!' });

        this.router.navigate(['/group_list']);
        this.spinner.hide();
      },
        (error) => {
          this.api.actionMessage.next({ type: 'error', message: 'Group not Deleted! Something went wrong.' });
          this.router.navigate(['/group_list']);
          this.spinner.hide();
        },
        () => {
        });
    }
  }

  fileProgress(evt: any) {
    this.fileData = evt.target.files[0] as File;
    this.preview();
    this.groupForm.controls.image_name.setValue(this.fileData.name);

    const files = evt.target.files;
    const file = files[0];

    if (files && file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }
  preview() {
    this.imageUpdate = 1;
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (event) => {
      this.previewUrl = reader.result;
    };
  }


  onSubmit() {
    this.isSubmited = true;
    const date = new Date();
    const timestamp = date.getTime();
    console.log(this.groupForm.value);
    if (this.groupForm.valid) {
      this.spinner.show();
      let imageData = {};
      if (this.imageUpdate) {
        this.groupForm.controls.image_name.setValue(`${timestamp}/${this.fileData.name}`);
        imageData = {
          key: this.api.key,
          secret: this.api.secret,
          name: `${this.groupForm.controls.image_name.value}`,
          filename: `groups/${this.groupForm.controls.image_name.value}`,
          filedata: this.base64textString,
          countryId: this.api.countryId,
          bucket: this.api.bucketName
        };
      }
      const group = this.groupForm.value;
      const finalgroupData = {
        group,
        imageData
      };
      // tslint:disable-next-line: no-shadowed-variable
      this.api.onCreateGroup(group, imageData).subscribe(finalgroupData => {
        let message = 'Group Added successfully.';
        let type = 'success';
        if (finalgroupData.affectedRows) {
          if (this.isEdit) {
            message = 'Group updated successfully.';
          }
        } else {
          message = 'Something went wrong!';
          type = 'error';
        }
        this.api.actionMessage.next({ type, message });
        this.router.navigate(['/group_list']);
        this.spinner.hide();
      },
        error => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
        () => { this.spinner.hide(); }

      );

    }

  }

  nameuploader(UpdaetdFile) {
    this.base64textString = UpdaetdFile;
  }


  onGetMembersAdmin(groupId): void {
    this.spinner.show();
    this.api.onGetMembersAdmin(groupId).subscribe(finalgroupData => {
      this.spinner.hide();
      
      //this.groupAdmin = finalgroupData.groupAdmin;
      if (finalgroupData.groupMembers.length > 0) {
        for (const members of finalgroupData.groupMembers) {
       //   this.groupMembers.push({ id: members.userId, name: members.name });
          if (finalgroupData.groupAdmin.length > 0) {
            for (const admmin of finalgroupData.groupAdmin) {
              if (admmin.adminId === members.userId) {
                this.groupAdmin.push(members.userId);
              }
            }
          }
        }
      }

      this.groupForm.patchValue({
        group_admin: this.groupAdmin
      });
      this.groupMembers = finalgroupData.groupMembers;


    },
      error => { this.spinner.hide(); this.notifier.notify('error', 'Something went wrong!'); },
      () => { this.spinner.hide(); }

    );

  }

}
