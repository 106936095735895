import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../services/api/apiservice.service';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.css']
})
export class AppheaderComponent implements OnInit {
  userName: any = '';
  profileImage = '';
  allNotification: any = [];
  totalNotification = 0;
  constructor(private api: ApiserviceService) { }

  ngOnInit() {
    this.api.userInformation.subscribe(data => {
      this.userName = data.username;
      this.api.currentUserId = data.id;

      let fullName = data.name;
      fullName = fullName.split(' ');
      console.log(fullName, fullName[0]);
      if (fullName[0] !== undefined) {
        this.userName = fullName[0];
        this.userName = this.titleCaseWord(this.userName);
      }

      if (data.profilePicture !== '' && data.profilePicture !== null) {
        this.profileImage = `${this.api.fileUrl}/users/${data.uuid}/${data.profilePicture}`;
      }
    },
      error => { console.log(error, 'error'); },
      () => {
        console.log('complete');
      });


    this.api.notificationData.subscribe(notificationVal => {
      this.totalNotification = 0;
      if (notificationVal.length > 0) {
        this.allNotification = notificationVal;
        if (notificationVal[0].activeStatus !== 0) {
          this.totalNotification = this.totalNotification + 1;
        }
        if (notificationVal[1].reportpendingToView !== 0) {
          this.totalNotification = this.totalNotification + 1;
        }
      }

    });
    this.onCallFunction();
  }

  onCallFunction()  {
    this.api.onGetNotification().subscribe(data => {
      this.api.notificationData.next(data);
    },
      (error) => {
      },
      () => {
      });
  }

  titleCaseWord(word: string) {
    if (!word) { return word; }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}
